import { useTranslation } from "react-i18next";
import {useCookie} from "../../sg-hooks/useCookie";
import {useEffect} from "react";

type TLanguageOptions = {
    value: string;
    text: string;
};

const Options: TLanguageOptions[] = [
    { value: "fi", text: "finnish" },
    { value: "se", text: "swedish" },
    { value: "en", text: "english" },
];

const yearInSeconds = 60 * 60 * 24 * 365;

/**
 * A simple hook for accessing the current language, language options and switcher function
 */
export const useLanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const { get, set } = useCookie("tulsu:language", { expires: yearInSeconds });

    useEffect(() => {
        const languageCookie = get();
        if (languageCookie.isSuccess) {
            i18n.changeLanguage(languageCookie.value as string).catch(console.error);
        }
    }, []);

    return {
        options: Options,
        currentLanguage: i18n.language,
        currentLanguageTransKey: Options.find(x => x.value === i18n.language)?.text,
        setLanguage: (languageKey: string) => {
            set(languageKey);

            document.documentElement.lang = languageKey;

            return i18n.changeLanguage(languageKey);
        },
    };
};
