import { cache, Cache, mutate, trigger } from "swr";
import { Mutator, Trigger } from "swr/dist/types";

export type UseSWRCacheHook = () => { cache: Cache, mutate: Mutator, trigger: Trigger  };

// Default implementation

export const useSWRCache: UseSWRCacheHook = () => {
    return {
        cache,
        mutate,
        trigger,
    };
};
