import { useState, useEffect } from "react";
import { useConst } from "./useConst";

export const useDebounce = <T>(value: T, debounce: number): T => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const timeout = setTimeout(() => setDebouncedValue(value), debounce);
        return () => clearTimeout(timeout);
    }, [value]);

    return debouncedValue;
};

export const useDebouncedFunction = <T, R>(value: T, fn: () => R, debounce: number): R => {
    const debouncedValue = useDebounce(value, debounce);

    const initial = useConst(fn);

    const [result, setResult] = useState<R>(initial);

    useEffect(() => {
        setResult(fn);
    }, [debouncedValue]);

    return result;
};
