import React from "react";
import { FieldRenderProps, RenderableProps } from "react-final-form";
import { Form } from "semantic-ui-react";
import { useConst } from "../../../sg-hooks/useConst";
import { useFinalFormShouldShowError } from "./adapter.helpers";

export const ToggleAdapter: RenderableProps<FieldRenderProps<any, any>>["component"] = ({
    input,
    meta,
    ...rest
}) => {

    /**
     * The checked-state of the input is controlled by FinalForm, so we want to ensure
     * we don't "force" it into any specific state. We use the initial checked state to
     * set the checked-value as per initial form data.
     * 
     * Without this, the toggles would behave unexpectedly when multiple toggles were made
     * in a short span of time (< 1000ms)
     */
    const initialChecked = useConst(() => !!input.checked);
    const { checked, ...inputWithoutChecked } = input;

    const showError = useFinalFormShouldShowError(meta);

    return (
        <Form.Radio
            {...inputWithoutChecked}
            type={undefined}
            toggle
            defaultChecked={initialChecked}
            label={<label className={rest.onlyMobileLabel ? "mobile only" : ""}>{rest.label}</label>}
            onChange={(event, { checked }) => input.onChange({ target: { value: checked } })}
            {...rest}
            error={showError ? meta.error : undefined}
        />
    );
};