import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import finnish from "./@locales/fi.json";
import swedish from "./@locales/se.json";
import english from "./@locales/en.json";

i18n
    .use(initReactI18next)
    .init({
        lng: "fi",
        fallbackLng: "fi",
        debug: process.env.NODE_ENV !== "production",
        interpolation: {
            escapeValue: false, // Not needed in React
        },
        resources: {
            fi: {
                translation: finnish,
            },
            se: {
                translation: swedish,
            },
            en: {
                translation: english,
            }
        },
    });
