import { TResult, makeFailure, makeSuccess } from "../sg-core/utils/Result";
import Cookies, { default as JSCookies } from "js-cookie";

const CookieManager = JSCookies.withConverter({
    read: (value) => {
        if (value) {
            try {
                return atob(value);
            } catch (err) {
                console.log(err);
                return value;
            }
        }
        return value;
    },
    write: (value): string => {
        try {
            return btoa(typeof value === "string" ? value : JSON.stringify(value));
        } catch (err) {
            return JSON.stringify(value);
        }
    }
});

/**
 * Generic interface for cookie management
 */
export interface IAppCookieStorage<T extends string | object> {
    /**
     * Get the value of the cookie
     */
    get: () => TResult<T>;
    /**
     * Set the value of the cookies
     * @param value The new value of the cookie
     * @param attributesOverride Optional extra attributes to be added to the cookie
     */
    set: (value: T, attributesOverride?: Cookies.CookieAttributes) => void;
    /**
     * Remove the cookie
     */
    delete: () => void;
}

/**
 * Hook for managing a single named cookie
 * @param key The name of the cookie to manage
 * @param attributes The default attributes to set for the cookie. Can be extended with the second parameter to the .set -function
 */
export const useCookie = <T extends string | object>(key: string, attributes: Cookies.CookieAttributes = {}): IAppCookieStorage<T> => {
    if (!key) {
        throw new Error("Cannot use cookie hook: No key provided");
    }
    return {
        get: () => {
            const data = CookieManager.get(key);

            if (data) {
                try {
                    return makeSuccess<T>(JSON.parse(data));
                } catch (jsonParseError) {
                    CookieManager.remove(key)
                    return makeFailure(jsonParseError);
                }
            }
            return makeFailure(new Error("ERR_NOT_IN_STORAGE"));
        },
        set: (value: T, attributesOverride?: Cookies.CookieAttributes) => {
            CookieManager.set(key, JSON.stringify(value), { ...attributes, ...attributesOverride });
        },
        delete: () => CookieManager.remove(key),
    };
};
