import React, { createContext, useContext, useEffect } from "react";
import { initialAuthContext } from "./interfaces/IAuthContext";
import { AuthenticationHook } from "./hooks/useAuthentication";

// Context, provider and standard hook
const context = createContext(initialAuthContext);
const Provider = context.Provider;
export const useAuthContext = () => useContext(context);

//
// Provider component

interface IAuthProviderProps {
    useAuthentication: AuthenticationHook;
}

export const AuthenticationProvider: React.FC<IAuthProviderProps> = ({ children, useAuthentication }) => {
    const authentication = useAuthentication();

    // Initialize authentication when the provider is rendered
    useEffect(() => {
        authentication.initialize();
    }, []);

    return (
        <Provider value={authentication}>
            {children}
        </Provider>
    );
};
