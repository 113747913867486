

/**
 * Encodes JSON to base64, and takes care of proper charecter encoding.
 * src: https://stackoverflow.com/a/30106551
 */
export const jsonToBase64 = (json: { [key: string]: any }) => {
    const str = JSON.stringify(json);
    const encoded = btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
        return String.fromCharCode(parseInt(p1, 16))
    }));
    return encoded
}



/**
 * Parses base64 encoded JSON, and takes care of proper charecter encoding.
 * src: https://stackoverflow.com/a/30106551
 */
export const base64ToJson = (base64Data?: string) => {

    if (!base64Data || base64Data.length === 0) {
        return {};
    }

    const decoded = decodeURIComponent(Array.prototype.map.call(atob(base64Data), (c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''));
    return JSON.parse(decoded)
}
