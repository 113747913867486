import React from "react";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../AuthenticationProvider";
import { PageContainer } from "../../@common/view/PageContainer";
import { Segment, Header, Icon } from "semantic-ui-react";

const useTextProps = () => {
    const { t } = useTranslation();

    return {
        startingApp: t("loading.starting"),
        wait: t("loading.wait"),
    };
};

export const AuthInitializeGuard: React.FC = ({ children }) => {
    const { initialized } = useAuthContext();

    return initialized
        ? <>{children}</>
        : <StillInitializing />;
};

const StillInitializing: React.FC = () => {
    const texts = useTextProps();

    return (
        <PageContainer>
            <Segment placeholder padded="very" basic textAlign="center" className="fullpage_loading_segment">
                <Header as="h2">{texts.startingApp}</Header>
                <p><Icon name="circle notched" loading /> {texts.wait}</p>
            </Segment>
        </PageContainer>
    );
};
