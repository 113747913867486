import React, { ErrorInfo, useEffect } from "react";
import { Segment, Header, Button, Divider, Message } from "semantic-ui-react";
import { PageContainer } from "../../view/PageContainer";
import { withRouter, Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { useBool } from "../../../sg-hooks/useBool";
import { useAuthContext } from "../../../@authentication/AuthenticationProvider";

class RouteErrorBoundaryImpl extends React.Component<any, { hasError: boolean, errorText: string, error?: Error, info?: ErrorInfo }> {
    constructor(props: any) {
        super(props);
        this.state = {
            hasError: false,
            errorText: "",
        }
    }

    static getDerivedStateFromError(error: Error) {
        let errorText = error?.message || error;

        if (typeof errorText !== "string") {
            console.error("[FatalError] The following error happened, but cannot be displayed due to incorrect error format", error);
            errorText = "";
        }

        return {
            hasError: true,
            errorText,
            error,
        };
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        this.setState({ info });
    }

    render() {
        if (this.state.errorText?.toLowerCase() === "forbidden") {
            return <ForbiddenErrorContent onErrorClear={() => this.setState({ hasError: false, errorText: "" })} />
        }
        if (this.state.hasError) {
            return (
                <ErrorContent errorText={this.state.errorText} error={this.state.error} info={this.state.info} />
            );
        } else {
            return this.props.children || null;
        }
    }
}

const getTextProps = (t: TFunction) => ({
    errorTitle: t("routing.error.title"),
    errorDescription: t("routing.error.description"),
    errorWhatNow: t("routing.error.what_now"),
    returnText: t("routing.error.return_link"),
    toFrontpageText: t("routing.error.frontpage_link"),
    showDetails: t("routing.error.show_details"),
    errorMessage: t("routing.error.error_message"),
});

const ForbiddenErrorContent: React.FC<{ onErrorClear: () => void }> = ({ onErrorClear }) => {
    const { loginWithCookie } = useAuthContext();

    useEffect(() => {
        const login = async () => {
            const result = await loginWithCookie();
            console.log(result)
            //@ts-ignore
            if (result.isSuccess) {
                onErrorClear()
            }

            onErrorClear()
        }
        login()

    }, []);

    return null;
}

const ErrorContent: React.FC<{ errorText: string, error?: Error, info?: ErrorInfo }> = (props) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [detailsShown, showDetails] = useBool(false);

    const {
        errorDescription,
        errorTitle,
        errorWhatNow,
        returnText,
        toFrontpageText,
        showDetails: showDetailsText,
        errorMessage
    } = getTextProps(t);

    const { errorText, error, info } = props;

    return (
        <PageContainer>
            <Segment padded="very" color="red">
                <Header as="h2">{errorTitle}</Header>
                <p>{errorDescription}</p>

                <p>{errorWhatNow}</p>

                <p>
                    <Button onClick={history.goBack} primary type="button" icon="chevron left" content={returnText} />
                    <Link to="/">
                        <Button type="button" content={toFrontpageText} />
                    </Link>
                </p>

                <Divider />

                <p><a onClick={showDetails}>{showDetailsText}</a></p>

                {detailsShown && (
                    <Message info>
                        <pre>
                            {errorMessage}: {errorText}
                            <br /><br />
                            Stack:<br />{error?.stack}
                            <br /><br />
                            Component stack: {info?.componentStack}
                        </pre>
                    </Message>
                )}

            </Segment>
        </PageContainer>
    );
}

export const RouteErrorBoundary = withRouter(RouteErrorBoundaryImpl);
