import { RouteErrorBoundary } from '../components/error/RouteErrorBoundary';
import React from "react";
import { createElement } from "react";
import { Route } from "react-router-dom";

export interface IRoute {
    path: string;
    render: () => JSX.Element;
    exact?: boolean;
    requireAuthentication?: boolean;
}

export const renderRoute = (route: IRoute, authRouteComponent?: React.FC) => {

    const routeComponent = route.requireAuthentication ? authRouteComponent : Route;

    if (!routeComponent) {
        throw new Error("Authenticated route component not supplied, cannot render authenticated routes");
    }

    return createElement(routeComponent, {
        render: route.render,
        key: route.path,
        path: route.path,
        exact: route.exact,
    });
};

export const addErrorBoundaryToRoute = (route: IRoute): IRoute => ({
    ...route,
    render: withRouteErrorBoundary(route.render),
});

export const addErrorBoundaryToRoutes = (routes: IRoute[]): IRoute[] =>
    routes.map(addErrorBoundaryToRoute);

export const withRouteErrorBoundary = (component: React.ComponentType) => {
    return () => React.createElement(RouteErrorBoundary, null, createElement(component));
};
