import React, { Fragment } from "react";
import { PageContainer } from "../../@common/view/PageContainer";
import { useAuthContext } from "../AuthenticationProvider";
import { Segment, Header, Button, Message, Grid } from "semantic-ui-react";
import { Form, Field } from "react-final-form";
import { Form as SemanticForm } from "semantic-ui-react";
import { InputAdapter } from "../../@common/components/formAdapters/InputAdapter";
import { useTranslation } from "react-i18next";
import { ToggleAdapter } from "../../@common/components/formAdapters/ToggleAdapter";
import { Link } from "react-router-dom";
import { useQueryString } from "../../@common/navigation/useQueryString";
import { OldApplicationUserNotification } from "../../@common/components/layout/OldApplicationUserNotification";
import {GlobalLanguagePicker} from "../../@locales/components/GlobalLanguagePicker";

interface ILoginData { username: string, password: string, remember: boolean }

const useTextProps = () => {
    const { t } = useTranslation();
    return {
        title: t("generic.login"),
        username: t("generic.username"),
        password: t("generic.password"),
        remember: t("generic.rememberLogin"),
        loginFailed: t("generic.error.loginFailed"),
        login: t("generic.action.login"),
        noAccountYet: t("generic.noAccountYet"),
        gotoRegistration: t("generic.action.gotoRegistration"),
        forgotPassword: t("generic.forgotPassword")
    };
};

const LoginViewContent: React.FC = () => {
    const texts = useTextProps();

    const isOldAppUser = useQueryString().readBoolean("tulityolupa-kayttaja");

    const { logIn, loggingIn, loginError } = useAuthContext();

    const submit = (values: ILoginData) => {
        if (!loggingIn) {
            logIn(values.username, values.password, values.remember);
        }
    };

    // TODO: SUGGESTION: Clear password field on failed login?

    return (
        <Fragment>
            { isOldAppUser && <OldApplicationUserNotification /> }
            <Segment.Group>
                <Segment>

                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column width={12}>
                                <Header as="h1" content={texts.title} />
                            </Grid.Column>
                            <Grid.Column width={4} textAlign={"right"}>
                                <GlobalLanguagePicker />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>


                    <Form<ILoginData>
                        onSubmit={submit}
                        render={({ handleSubmit }) => (
                            <SemanticForm onSubmit={handleSubmit}>
                                <Field<string>
                                    name="username"
                                    type="text"
                                    component={InputAdapter}
                                    label={texts.username}
                                    required
                                    autoFocus
                                />

                                <Field<string>
                                    name="password"
                                    type="password"
                                    component={InputAdapter}
                                    label={texts.password}
                                    required
                                />

                                <Field<boolean>
                                    name="remember"
                                    type="checkbox"
                                    component={ToggleAdapter}
                                    label={texts.remember}
                                />

                                {loginError && (
                                    <Message negative>
                                        <Message.Header>{texts.loginFailed}</Message.Header>
                                        <Message.Content>{loginError}</Message.Content>
                                    </Message>
                                )}

                                <Grid columns={2} stackable>
                                    <Grid.Column>
                                        <Button fluid primary size="large" icon="key"
                                            content={texts.login} type="submit" loading={loggingIn} />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Link to="/unohtunut-salasana">{texts.forgotPassword}</Link>
                                    </Grid.Column>
                                </Grid>
                            </SemanticForm>
                        )}
                    />
                </Segment>

                <Segment secondary textAlign="center">
                    <p>{texts.noAccountYet}? <Link to="/rekisteroidy">{texts.gotoRegistration}</Link></p>
                </Segment>
            </Segment.Group>
        </Fragment>
    );
};

const LoginView = () => (
    <PageContainer>
        <LoginViewContent />
    </PageContainer>
);

export default LoginView;
