import React, { ErrorInfo } from "react";

class ErrorBoundary extends React.Component<any, { hasError: boolean, errorText: string, error?: Error, info?: ErrorInfo }> {
    constructor(props: any) {
        super(props);
        this.state = {
            hasError: false,
            errorText: "",
        }
    }

    static getDerivedStateFromError(error: Error) {
        let errorText = error?.message || error;

        if (typeof errorText !== "string") {
            console.error("[FatalError] The following error happened, but cannot be displayed due to incorrect error format", error);
            errorText = "";
        }

        return {
            hasError: true,
            errorText,
            error,
        };
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        console.error(error);
        console.error(info);

        this.setState({ info });
    }

    render() {
        if (this.state.hasError) {
            return null;
        } else {
            return this.props.children || null;
        }
    }
}
export default ErrorBoundary
