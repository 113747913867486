import { IUserBasicInfo } from '../models/IUserBasicInfo';
import { getSgApiService } from '../../@api/services/ApiService';
import { createUserService } from '../services/UserService';
import { UserCacheKeys } from '../cacheKeys';
import useSWR, { trigger } from 'swr';
import { useAuthContext } from '../AuthenticationProvider';
import {TulsuLogger} from "../../@logger/TulsuLogger";

export type UseUserBasicInfoHook = () => IUserBasicInfo;

export const useUserBasicInfoInvalidator = () => {
    const { token, user } = useAuthContext();
    const key = user ? UserCacheKeys.basicInfo(token) : null;

    return () => {
        trigger(key, true)
            .catch(e => TulsuLogger.warn("Triggered an update to user basic info in {hookName} but it failed with the following error message: '{errorMessage}'", {
                hookName: "useUserBasicInfoInvalidator",
                errorMessage: e?.message ?? e.toString(),
            }));
    }
};

export const useUserBasicInfo: UseUserBasicInfoHook = (): IUserBasicInfo => {
    const { token, user } = useAuthContext();

    if (!user) {
        throw new Error("Attempted to fetch user information before a valid login existed, do not call useUserBasicInfo() without a login guard");
    }

    const fetcher = async () => {
        const userService = createUserService(getSgApiService(token));

        const result = await userService.getUserInfo(user!.id);

        if (result.isSuccess) {
            return result.value;
        } else {
            throw result.error;
        }
    };

    const key = user ? UserCacheKeys.basicInfo(token) : null;

    const { data } = useSWR<IUserBasicInfo>(key, fetcher, {
        suspense: true,
        focusThrottleInterval: 60 * 1000,
        dedupingInterval: 5000,
    });

    return data!;
};

