import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Header, Segment, Image } from "semantic-ui-react";
import hotWorkImage from "../../../images/infobanneri-bg.png";

export const OldApplicationUserNotification: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Segment raised className="old-user-notification">
      <div className="image-container">
        <Image src={hotWorkImage} alt="" size="small" circular floated="left" />
      </div>
      <Header as="h3">
        <Header.Content>
          {t("generic.oldAppNotification.title")}
          <Header.Subheader>
            <Trans i18nKey="generic.oldAppNotification.description">
              Tulityolupa.fi-palvelu on uudistunut. Jos haluat vanhaan
              palveluun,
              <a href="https://gate.systemsgarden.com/?theme=tulityolupa">
                Siirry sinne tästä
              </a>
              <a href="/unohtunut-salasana">Olen unohtanut salasanani</a>
              <a href="mailto:tulsu@gate-apps.com">Asiakaspalvelumme auttaa</a>
            </Trans>
          </Header.Subheader>
        </Header.Content>
      </Header>
    </Segment>
  );
};
