import fetch from "unfetch";
import { IEndpoint } from "./EndpointService";
import { TResult, makeFailure, makeSuccess } from "../../sg-core/utils/Result";

export enum GeneratedAction {
    GetList,
    GetOne,
    Create,
    Update,
    Delete
}

type TSendOptions = {
    headers?: Record<string, string | number>;
};

export interface ISgApiService {
    getToken: () => string | undefined;
    sendHttpRequest: <T>(endpoint: IEndpoint, type: string, body: any, headers: any) => Promise<TResult<T>>;
    sendHttpUrlRequest: <T>(url: string, method: string, body: any, headers?: any, noToken?: boolean) => Promise<TResult<T>>;
    sendAction: <T>(endpoint: IEndpoint, action: string, body: any, noToken?: boolean, opts?: TSendOptions) => Promise<TResult<T>>;
    sendActionBlobBody: (endpoint: IEndpoint, action: string, body: any, noToken?: boolean) => Promise<TResult<Blob>>;
    sendActionGenerated: <TEntity>(endpoint: IEndpoint, entityName: string, action: GeneratedAction, body?: object) => Promise<TResult<{ data: TEntity, response?: any }>>;
}

export const getSgApiService = (token?: string): ISgApiService => {

    let TOKEN = token;

    const getToken = () => {
        if (!TOKEN) {
            throw new Error("Cannot send a request with token if token is not set.");
        }
        return `NAS SID ${TOKEN}`;
    };

    const sendHttpRequest = async <T>(endpoint: IEndpoint, method: string, body: any, headers: any) => {
        try {
            const response = await fetch(endpoint.path, {
                method,
                body: JSON.stringify(body),
                headers,
            });

            if (!response.ok) {
                return makeFailure(new Error(response.statusText));
            }

            const json = await response.json();
            if (json.success === false) {
                return makeFailure(new Error(json.error.message));
            }
            return makeSuccess(json);
        } catch (e) {
            return makeFailure(e);
        }
    };

    const sendHttpUrlRequest = async<T>(url: string, method: string, body: any = {}, headers: any = {}, noToken?: boolean): Promise<TResult<T>> => {
        try {
            const hdrs = { ...headers };
            if (!noToken) {
                hdrs.Authorization = getToken();
            }
            const response = await fetch(url, {
                method,
                body: body ? JSON.stringify(body) : undefined,
                headers: hdrs,
            });

            if (response.ok) {
                const data: T = await response.json();

                if ((data as any).error) {
                    return makeFailure(new Error((data as any).error.message));
                }

                return makeSuccess(data);
            }
            return makeFailure(new Error("Unknown request error"));
        } catch (e) {
            return makeFailure(e);
        }
    };

    const sendAction = async <P, T>(endpoint: IEndpoint, action: string, payload: P, noToken: boolean = false, opts?: TSendOptions) => {
        const body = {
            ...payload,
            action,
        };

        const headers: Record<string, string | number> = opts?.headers || {};
        if (!noToken) {
            headers.Authorization = getToken();
        }

        return await sendHttpRequest<T>(endpoint, "POST", body, headers);
    };

    const sendActionBlobBody = async <P>(endpoint: IEndpoint, action: string, payload: P, noToken: boolean = false): Promise<TResult<Blob>> => {
        // Sanity check
        if (!noToken && !token) {
            throw new Error(`No token specified for a request with token. Request target = '${endpoint.path}' action = '${action}'`);
        }

        const body = {
            ...payload,
            action,
        };

        const headers: Record<string, string> = {};
        if (!noToken) {
            headers.Authorization = token!;
        }

        try {
            const response = await fetch(endpoint.path, {
                method: "POST",
                headers,
                body: JSON.stringify(body),
            });

            if (response.ok) {
                return makeSuccess(await response.blob());
            } else {
                return makeFailure(new Error(response.statusText));
            }
        } catch (requestError) {
            return makeFailure(requestError);
        }
    };

    const getGeneratedAction = (entityName: string, action: GeneratedAction): string => {
        switch (action) {
            case GeneratedAction.Create:
                return `lisaa_${entityName}`;
            case GeneratedAction.Delete:
                return `poista_${entityName}`;
            case GeneratedAction.GetList:
                return `hae_${entityName}_lista`;
            case GeneratedAction.GetOne:
                return `hae_${entityName}`;
            case GeneratedAction.Update:
                return `tallenna_${entityName}`;
        }
    }

    /**
     * Sends a predefined action to a generated API interface
     * @param entityName Name of the entity to find
     * @param action Action type we want to perform
     * @param body Request body
     * @param noToken Anonymous or not?
     */
    const sendActionGenerated = async<TEntity>(endpoint: IEndpoint, entityName: string, action: GeneratedAction, body: any = {}, noToken: boolean = false): Promise<TResult<{ data: TEntity, response?: any }>> => {
        const actionName = getGeneratedAction(entityName, action);

        const response = await sendAction<any, any>(endpoint, actionName, body, noToken);

        if (response.isSuccess) {
            const resultObj = response.value;

            // In GATE generated APIs, the result is either in "results" -property (if it's a list)
            // or [entityName] -object.
            // If it's neither, we give up.
            const result = resultObj.results ?? resultObj[entityName];

            if ((typeof result) === "undefined") {
                return makeFailure(new Error(`Invalid response: expected '${entityName}' or 'results' property`));
            }

            return makeSuccess({
                data: result === "" ? [] : result,
                response: response.value,
            });
        }
        return response;
    };

    return {
        sendHttpRequest,
        sendHttpUrlRequest,
        sendAction,
        sendActionGenerated,
        sendActionBlobBody,
        getToken: () => token,
    };
};
