import React, { Fragment } from "react";
import { useCurrentPermissions } from "../../hooks/useCurrentPermissions";

interface IOnlyForProps {
    issuers?: boolean;
    maintainers?: boolean;
}

export const OnlyFor: React.FC<IOnlyForProps> = (props) => {
    const {
        isIssuer,
        isMaintainer,
    } = useCurrentPermissions();

    if (props.issuers && !isIssuer) {
        return null;
    }

    if (props.maintainers && !isMaintainer) {
        return null;
    }

    return (
        <Fragment>{props.children}</Fragment>
    );
};

export const OnlyForMaintainers: React.FC = ({ children }) => (
    <OnlyFor maintainers>
        { children }
    </OnlyFor>
);

export const OnlyForIssuers: React.FC = ({ children }) => (
    <OnlyFor issuers>
        { children }
    </OnlyFor>
);

