import { useCompanyContext } from '../../@company/context/CompanyContext';
import { useUserBasicInfo } from './userUserBasicInfo';
import { useAuthContext } from "../AuthenticationProvider";
import { IUser } from "../interfaces/IUser";
import { IPerson } from "../../@common/models/Person";

export const useLoggedInUser = (): IUser => {
    const user = useAuthContext().user;

    if (!user) {
        throw new Error("Invalid operation: cannot retrieve user when the user is not logged in");
    }
    
    return user;
};

export const useLoggedInPerson = (opts: { undefinedOnError?: boolean } = {}): IPerson => {
    const { persons } = useUserBasicInfo();
    const { currentCompanyId } = useCompanyContext();

    if (!opts.undefinedOnError && (!currentCompanyId || currentCompanyId === -1)) {
        throw new Error("Do not call useLoggedInPerson if no company is selected. You can prevent this error with SelectedCompanyGuard");
    }

    return persons.find((person) => person.companyId === currentCompanyId)!;
};
