import { IUser } from './../../@authentication/interfaces/IUser';
import { IMapper } from './IMapper';
import { IUserDTO } from '../dtos/IUserDTO';
import mapKeys from "lodash/mapKeys";

// User login fields have no kay_ -prefix, so we add it to make it work with UserMapper
const applyKayPrefix = (obj: any): IUserDTO => {
    const mapped = mapKeys(obj, (_, key: string) => {
        if (key.toString().startsWith("kay_")) {
            return key;
        } else {
            return `kay_${key}`;
        }
    }) as IUserDTO;
    
    return mapped;
};

export const UserMapper: IMapper<IUserDTO, IUser> = {
    apiToDomain: (api) => {
        return ({
            id: api.id,
            username: api.meiliosoite ?? "",
            firstName: api.etunimi ?? "",
            surname: api.sukunimi ?? "",
            phone: api.puhelin ?? "",
        });
    },
    domainToApi: (domain) => {
        return {
            id: domain.id,
            etunimi: domain.firstName,
            sukunimi: domain.surname,
            meiliosoite: domain.username,
            puhelin: domain.phone,
        };
    },
};