import React, { useEffect, useState } from "react";
import { useCompanyEditor } from "../../hooks/useCompanyEditor";
import type { ICompany } from "../../models/ICompany";
import { Form as SemanticForm, Header, Grid, Button, Message, Segment } from "semantic-ui-react";
import { Form, Field } from "react-final-form";
import { InputAdapter } from "../../../@common/components/formAdapters/InputAdapter";
import { useTranslation } from "react-i18next";
import {resolveCompanyCreateErrorMessage} from "../../errors/companyCreateErrors";

interface ICompanyFormProps {
    company?: ICompany;
    onSave?: (saved: ICompany) => void;
    onCancel?: () => void;
    requiredFields?: (keyof ICompany)[];
}

const useTextProps = () => {
    const { t } = useTranslation();

    return {
        basicInfo: t("company.fields.basic_info"),
        name: t("company.fields.name"),
        contactInfo: t("company.fields.contact_info"),
        postNumber: t("company.fields.post_number"),
        streetAddress: t("company.fields.street_address"),
        city: t("company.fields.city"),
        save: t("generic.save"),
        cancel: t("generic.cancel"),
        saveError: t("generic.error.save_error"),
        vatNumber: t("company.fields.vat_number"),
    };
};

export const CompanyForm: React.FC<ICompanyFormProps> = ({ company, onSave, onCancel, ...restProps }) => {
    const {
        formData,
        setFormData,
        original,
        save,
        saving,
        lastSaved,
        saveError,
        validate,
    } = useCompanyEditor(company);

    const { t } = useTranslation();
    const texts = useTextProps();

    const [doSave, setDoSave] = useState(false);

    const submit = (values: ICompany) => {
        setFormData(values);
        setDoSave(true);
    };

    useEffect(() => {
        if (lastSaved && onSave) {
            onSave(original!);
        }
    }, [lastSaved]);

    useEffect(() => {
        if (doSave) {
            save();
        }
    }, [doSave]);

    const requiredFields = restProps.requiredFields ?? [];

    return (
        <Form<ICompany>
            initialValues={formData}
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit }) => (
                <SemanticForm onSubmit={handleSubmit} loading={saving}>
                    <Grid stackable columns={2}>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Header as="h3">{texts.basicInfo}</Header>

                                <Field
                                    name="name"
                                    component={InputAdapter}
                                    required
                                    label={texts.name}
                                />

                                <Field
                                    name="vatNumber"
                                    component={InputAdapter}
                                    required={requiredFields.includes("vatNumber")}
                                    label={texts.vatNumber}
                                />

                                <Header as="h3">{texts.contactInfo}</Header>

                                <Field
                                    name="address"
                                    component={InputAdapter}
                                    required={requiredFields.includes("address")}
                                    label={texts.streetAddress}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Field
                                    name="postNumber"
                                    component={InputAdapter}
                                    required={requiredFields.includes("postNumber")}
                                    label={texts.postNumber}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Field
                                    name="city"
                                    component={InputAdapter}
                                    required={requiredFields.includes("city")}
                                    label={texts.city}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    { saveError && (
                        <Message negative>
                            <Message.Header>{texts.saveError}</Message.Header>
                            <Message.Content>{resolveCompanyCreateErrorMessage(saveError, t)}</Message.Content>
                        </Message>
                    ) }

                    <Segment textAlign="right">
                        { onCancel && (<Button type="button" onClick={onCancel} content={texts.cancel} />) }
                        <Button primary type="submit" icon="save" content={texts.save} />
                    </Segment>

                </SemanticForm>
            )}
        />
    );
};
