import { createContext, useContext, useState, useMemo } from "react";

import React from "react";

/**
 * Currently the only thing this context provider is a global state for
 * the currently selected company that the user is using.
 */

const COMPANY_CONTEXT_STORAGE_KEY = "tulsu:company";

interface ICompanyContext {
    currentCompanyId?: number;
    setCurrentCompany: (id: number) => void;
}

const noop = () => undefined;

const defaultValue: ICompanyContext = {
    currentCompanyId: -1,
    setCurrentCompany: noop,
};

const context = createContext<ICompanyContext>(defaultValue);
const { Provider } = context;

export const useCompanyContext = () => useContext(context);


// Provider
export const CompanyProvider: React.FC = ({ children }) => {

    const initialCompanyId = useMemo(() => {
        const fromStorage = localStorage.getItem(COMPANY_CONTEXT_STORAGE_KEY);

        if (fromStorage !== null) {
            const parsed = parseInt(fromStorage, 10);
            return Number.isNaN(parsed) ? undefined : parsed;
        }
    }, []);

    const [currentCompanyId, setCurrentCompanyId] = useState(initialCompanyId);

    const value = useMemo((): ICompanyContext => {

        const setCurrentCompany = (id: number) => {
            setCurrentCompanyId(id);
            localStorage.setItem(COMPANY_CONTEXT_STORAGE_KEY, id.toString());
        };

        return {
            currentCompanyId,
            setCurrentCompany,
        }
    }, [currentCompanyId]);

    return (
        <Provider value={value}>
            { children }
        </Provider>
    );
};
