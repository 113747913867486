import React from "react";
import {useLanguageSwitcher} from "../../@locales/hooks/useLanguageSwitcher";
import {Dropdown} from "semantic-ui-react";
import {useTranslation} from "react-i18next";


export const TopNavLanguagePicker: React.FC = () => {
    const {t} = useTranslation();
    const language = useLanguageSwitcher();

    return (
        <Dropdown item text={t(`languages.${language.currentLanguageTransKey}`)}>
            <Dropdown.Menu>
                {language.options.map((lng) => (
                    <Dropdown.Item
                        key={lng.value}
                        content={t(`languages.${lng.text}`)}
                        active={lng.value === language.currentLanguage}
                        onClick={() => language.setLanguage(lng.value)}
                    />
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};
