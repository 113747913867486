import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';
import { useMemo } from "react";

export const useQueryString = () => {
    const history = useHistory();
    const location = useLocation();

    return useMemo(() => {
        const getRawString = () => window.location.search;

        const getQuery = () => {
            return qs.parse(getRawString());
        };

        const getValue = (name: string) => {
            const queryParams = getQuery();

            return queryParams[name];
        };

        const getAsObject = () => getQuery();

        const readBoolean = (name: string) => {
            return !!getValue(name);
        };

        const readNumber = (name: string, withDefault?: number) => {
            const value = getValue(name);

            if (value) {
                if (Array.isArray(value)) {
                    return undefined;
                }
                return parseInt(value, 10);
            }

            if (typeof withDefault === "number") {
                writeValue(name, withDefault.toString());
                return withDefault;
            }
        };

        const readString = (name: string) => {
            const value = getValue(name);

            if (Array.isArray(value)) {
                return value[0];
            }
            return value;
        };

        const writeValue = (name: string, value: string | number | boolean) => {
            const query = getQuery();
            query[name] = value?.toString();

            const encodedQuery = '?' + qs.stringify(query);

            history.push(location.pathname + encodedQuery);
        };

        const clear = (push?: boolean) => {
            if (push) {
                history.push(location.pathname);
            } else {
                history.replace(location.pathname);
            }
        };

        return {
            readBoolean,
            readNumber,
            readString,
            writeValue,
            getRawString,
            getAsObject,
            clear,
        };
    }, []);
};
