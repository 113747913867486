import React, { Fragment, useEffect } from "react";
import { Segment, Header, List, Button } from "semantic-ui-react";
import { useCompanyContext } from "../../context/CompanyContext";
import { useUserBasicInfo } from "../../../@authentication/hooks/userUserBasicInfo";
import { CompanyCreateForm } from "../forms/CreateForm";
import { useCreateCompany } from "../../hooks/useCreateCompany";
import { PageContainer } from "../../../@common/view/PageContainer";
import { CompanySelector } from "../selectors/CompanySelector";
import {useTranslation} from "react-i18next";

const NO_COMPANY_ID = -1;
const COMPANY_DROPDOWN_CUTOFF = 5;

/**
 * This component dims it's children and displays a company creator/selector if no company
 * is currently selected.
 */

export const SelectedCompanyGuard: React.FC = ({ children }) => {
  const texts = useTextProps();
  const { currentCompanyId, setCurrentCompany } = useCompanyContext();

  const { companies } = useUserBasicInfo();

  const hasCompanies = companies.length > 0;

  const company = companies.find((c) => c.id === currentCompanyId);

  useEffect(() => {
    // Pick the first item in the list, but only if there's only one company.
    if (
        (!currentCompanyId || currentCompanyId === NO_COMPANY_ID) &&
        companies.length === 1
    ) {
      setCurrentCompany(companies[0].id);
    }

    // If company doesn't exists... (this can happen on user change)
    if (!companies.some((company) => company.id === currentCompanyId)) {
      if (currentCompanyId !== NO_COMPANY_ID) {
        setCurrentCompany(NO_COMPANY_ID);
      }
    }
  }, [companies, currentCompanyId]);

  if (currentCompanyId && company) {
    return <Fragment>{children}</Fragment>;
  } else {
    return (
        <PageContainer>
          <Segment.Group>
            <Segment>
              { !hasCompanies && (
                  <Fragment>
                    <Header as="h1">{texts.addCompany}</Header>

                    <p>{texts.userHasNoCompanies}</p>
                    <p>{texts.userHasNoCompaniesDescription}</p>
                  </Fragment>
              ) }
              { hasCompanies && (
                  <Fragment>
                    <Header as="h1" content={texts.selectCompany} />
                    <p>{texts.noCompanySelected}</p>
                    <p>{texts.companyCanBeChanged}</p>

                    { companies.length <= COMPANY_DROPDOWN_CUTOFF && (
                        <List relaxed>
                          { companies.map((company) => (
                              <List.Item key={company.id}>
                                <Button primary size="tiny" type="button" content={texts.select} onClick={() => setCurrentCompany(company.id)} /> { company.name }
                              </List.Item>
                          )) }
                        </List>
                    )}

                    { companies.length > COMPANY_DROPDOWN_CUTOFF && (
                        <CompanySelector
                            companies={companies}
                            onCompanySelect={setCurrentCompany}
                            selectOnBlur={false}
                            className="selection"
                        />
                    ) }

                  </Fragment>

              ) }

            </Segment>

            <Segment>
              <Header as="h3">{texts.addNewCompany}</Header>

              <p>{texts.addNewCompanyDescription}</p>

              <CompanyCreateForm
                  createHook={useCreateCompany}
                  onCreated={(company) => setCurrentCompany(company.id)}
              />
            </Segment>
          </Segment.Group>
        </PageContainer>
    );
  }
};

const useTextProps = () => {
  const { t } = useTranslation();

  return {
    select: t("generic.select"),
    addCompany: t("company.create"),
    addNewCompany: t("user.company.add_new"),
    addNewCompanyDescription: t("user.company.add_new_info"),
    userHasNoCompanies: t("user.company.user_doesnt_belong_to_company"),
    userHasNoCompaniesDescription: t("user.company.user_doesnt_belong_to_company_info"),
    noCompanySelected: t("user.company.no_company_selected"),
    selectCompany: t("user.company.select_company"),
    addCompanyInfo: t("user.company.add_new_info"),
    companyCanBeChanged: t("user.company.company_can_be_changed"),
  };
};
