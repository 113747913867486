import {TCookieConsentData} from "../../models/cookieConsent/TCookieConsentData";
import {get as getCookie, set as setCookie} from "js-cookie";
import {CookieConsentConstants} from "./constants";

export const getConsentCookie = (): TCookieConsentData => {
    const cookieValue = getCookie(CookieConsentConstants.CookieName)?.split(CookieConsentConstants.Separator) || [];

    const consentData: TCookieConsentData = {
        consentAsked: false,
        mandatoryAccepted: false,
        analyticsAccepted: false,
    };

    if (cookieValue.includes(CookieConsentConstants.Mandatory)) {
        consentData.mandatoryAccepted = true;
    }
    if (cookieValue.includes(CookieConsentConstants.Analytics)) {
        consentData.analyticsAccepted = true;
    }
    if (cookieValue.includes(CookieConsentConstants.ConsentAsked)) {
        consentData.consentAsked = true;
    }

    return consentData;
};

export const setConsentCookie = (value: TCookieConsentData): void => {
    const acceptedCookiesList: string[] = [CookieConsentConstants.ConsentAsked];

    if (value.analyticsAccepted) { acceptedCookiesList.push(CookieConsentConstants.Analytics); }
    if (value.mandatoryAccepted) { acceptedCookiesList.push(CookieConsentConstants.Mandatory); }

    const cookieValue = acceptedCookiesList.join(CookieConsentConstants.Separator);

    setCookie(CookieConsentConstants.CookieName, cookieValue, {
        expires: CookieConsentConstants.ExpirationDays,
    });
};
