export interface ISuccessResult<T> {
    isSuccess: true;
    isFailure: false;
    value: T;
}

export interface IFailureResult {
    isSuccess: false;
    isFailure: true;
    error: Error;
}

export type TResult<T> = ISuccessResult<T> | IFailureResult;

export function makeSuccess<T>(value: T): ISuccessResult<T> {
    return {
        isSuccess: true,
        isFailure: false,
        value,
    };
}

const errorTypeGuard = (error: any): Error => {
    if (error instanceof Error) {
        return error;
    }

    const type = typeof (error);

    if (error === null || error === undefined) {
        return new Error("Error is null or undefined");
    }

    if (["string", "number", "boolean"].includes(type)) {
        return new Error(`Error type: ${type} - ${error.toString()}`);
    }

    return new Error("Invalid error type. Objects and arrays are not valid errors.");
};

export function makeFailure(error: Error | any): IFailureResult {
    return {
        isSuccess: false,
        isFailure: true,
        error: errorTypeGuard(error),
    };
}

export function combineResults(results: Array<TResult<any>>): TResult<any> {
    const firstError = results.find((res) => res.isFailure);

    return firstError || makeSuccess(true);
}
