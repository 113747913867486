import { produce } from 'immer';
import { useMemo, useReducer } from "react";

interface IUseLoadingData<TError> {
    loading: boolean;
    loadError?: TError;
    startLoading: () => void;
    loadSuccess: () => void;
    loadErrored: (error: TError) => void;
}

export type UseLoadingDataHook<TError = string> = () => IUseLoadingData<TError>;

interface ILoadingState<TError> {
    loading: boolean;
    error?: TError;
}

enum EAction { Start, Success, Error }
type TAction<TError>
    = { type: EAction.Start | EAction.Success }
    | { type: EAction.Error, error: TError };

const DEFAULT_STATE: ILoadingState<any> = { loading: false }; 

function reducer<TError>(state: ILoadingState<TError>, action: TAction<TError>): ILoadingState<TError> {
    return produce(state, (draft) => {
        switch (action.type) {
            case EAction.Start:
                draft.error = undefined;
                draft.loading = true;
                break;
            case EAction.Success:
                draft.error = undefined;
                draft.loading = false;
                break;
            case EAction.Error:
                draft.error = action.error as any;
                draft.loading = false;
        }
    });
};

export const useLoading = <TError = string>() => {
    const [state, dispatch] = useReducer(reducer, DEFAULT_STATE);

    return useMemo((): IUseLoadingData<TError> => {

        const startLoading = () => {
            dispatch({ type: EAction.Start });
        };

        const loadSuccess = () => {
            dispatch({ type: EAction.Success });
        };

        const loadErrored = (error: TError) => {
            dispatch({ type: EAction.Error, error });
        };

        return ({
            loading: state.loading,
            loadError: state.error as TError,
            startLoading,
            loadErrored,
            loadSuccess,
        });
    }, [state]);
};
