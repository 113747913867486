import React from "react";
import { FieldRenderProps, RenderableProps } from "react-final-form";
import { Form } from "semantic-ui-react";
import { useDebounce } from "../../../sg-hooks/useDebounce";
import { useFinalFormShouldShowError } from "./adapter.helpers";

export const InputAdapter: RenderableProps<FieldRenderProps<any, any>>["component"] = ({
    input,
    meta,

    ...rest
}) => {
    const errorMessage = useFinalFormShouldShowError(meta) ? meta.error : undefined; 
    const error = useDebounce(errorMessage, 200);
    const validating = useDebounce((meta.validating && !meta.invalid && !meta.valid), 200);

    return (
        <Form.Input
            {...input}
            onChange={(event) => {
                input.onChange({
                    ...event,
                    target: {
                        ...event.target,
                        value: event.target.value || null
                    }
                })
            }}
            loading={validating}
            error={error}
            {...rest}
        />
    );
};