import {useState} from "react";
import {useConst} from "./useConst";

type UseBoolResult = [
    boolean,
    () => void,
    () => void,
];

export const useBool = (initial: boolean = false): UseBoolResult => {
    const [value, setValue] = useState(initial);

    const setTrue = useConst(() => () => setValue(true));
    const setFalse = useConst(() => () => setValue(false));

    return [
        value,
        setTrue,
        setFalse,
    ];
};
