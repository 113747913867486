import { UserCacheKeys } from '../../@authentication/cacheKeys';
import { getSgApiService } from '../../@api/services/ApiService';
import { useAuthContext } from '../../@authentication/AuthenticationProvider';
import _omit from "lodash/omit";
import { mutate, trigger } from "swr";
import { ICompany } from "../models/ICompany";
import { useLoading } from "../../sg-hooks/useLoading";
import { useMemo, useState } from "react";
import { createCompanyService } from "../services/CompanyService";
import { CompanyCacheKeys } from "../cacheKeys";

type CompanyFormData = Omit<ICompany, "id" | "permitLicences">;

export interface ICompanyEditorData {
    readonly formData: CompanyFormData;
    readonly saving: boolean;
    readonly isNew: boolean;
    readonly saveError?: string;
    readonly lastSaved?: Date;
    readonly original?: ICompany;
    readonly setFormData: (data: CompanyFormData) => void;
    readonly save: () => void;
    readonly validate: (data: CompanyFormData) => Record<string, string>;
}

export type CompanyEditorHook = (company?: ICompany) => ICompanyEditorData;

// Default implementation

const defaultData: CompanyFormData = {
    address: "",
    city: "",
    name: "",
    postNumber: "",
    vatNumber: "",
};

export const useCompanyEditor: CompanyEditorHook = (company) => {

    // Keep track of whether or not we're creating a new company
    const [isNew, setIsNew] = useState(!company?.id);

    // The original, unedited data. This will be updated when a save completes
    const [original, setOriginal] = useState(company);

    // Timestamp for when the data was last saved (or null if the form is fresh)
    const [lastSaved, setLastSaved] = useState<Date>();

    // The form data, this is the data that will be sent when save is called
    const [formData, setFormData] = useState(
        company ? { ...defaultData, ..._omit(company, ["id"]) } : defaultData,
    );

    // Load state
    const {
        loading: saving,
        loadError: saveError,
        loadErrored: saveErrored,
        loadSuccess: saveSuccess,
        startLoading: startSaving,
    } = useLoading();

    const { token } = useAuthContext();

    return useMemo(() => {

        // Validator function (designed to be FinalForm-compatible)
        const validate = (data: CompanyFormData): Record<string, string> => {
            const errors: Record<string, string> = {};

            if (data.postNumber && !/[0-9]{5}/.test(data.postNumber)) {
                errors.postNumber = "Virheellinen postinumero";
            }

            return errors;
        };

        /**
         * The save function
         * The function chooses the correct save functionality (create / update)
         * depending on the isNew flag.
         */
        const save = async () => {
            if (!saving) {
                const service = createCompanyService(getSgApiService(token));

                try {
                    startSaving();

                    const result = isNew
                                    ? await service.createCompany({...company!, ...formData })
                                    : await service.saveCompany(company!.id, { ...company!, ...formData });
                    if (result.isSuccess) {
                        if (!isNew) {
                            mutate(CompanyCacheKeys.CompanyInstance((result.value as any).id), result.value);
                        }

                        trigger(UserCacheKeys.basicInfo(token));

                        setOriginal(isNew ? (result.value as any).company : result.value);
                        setFormData(_omit(original, ["id"]));
                        setLastSaved(new Date());
                        setIsNew(false);
                        saveSuccess();
                    } else {
                        saveErrored(result.error.message);
                    }
                } catch (saveError) {
                    saveErrored(saveError.message);
                }
            }
        };

        return {
            saving,
            saveError,
            formData,
            setFormData,
            isNew,
            lastSaved,
            save,
            original,
            validate,
        };
    }, [original, formData, lastSaved, saving, saveError]);
};
