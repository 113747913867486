import { ICompany } from '../../@company/models/ICompany';
import { ICompanyDTO } from '../dtos/ICompanyDTO';
import { IMapper } from './IMapper';

const defaultEmptyString = (v?: string | null): string => {
    if (v === "null")
        return "";
    return v ?? "";
};

export const CompanyMapper: IMapper<ICompanyDTO, ICompany> = {
    apiToDomain: (api) => {
        return ({
            id: api.id,
            address: defaultEmptyString(api.osoite),
            city: defaultEmptyString(api.postitoimipaikka),
            name: defaultEmptyString(api.nimi),
            postNumber: defaultEmptyString(api.postinumero),
            vatNumber: defaultEmptyString(api.y_tunnus),
            permitLicences: {
                total: parseInt(api.hankittu_lupa_lkm?.toString()!, 10) || 0,
                remaining: parseInt(api.jaljella_lupa_lkm?.toString()!, 10) || 0,
            },
        });
    },
    domainToApi: (domain) => ({
        id: domain.id,
        nimi: defaultEmptyString(domain.name),
        osoite: defaultEmptyString(domain.address),
        postinumero: defaultEmptyString(domain.postNumber),
        postitoimipaikka: defaultEmptyString(domain.city),
        y_tunnus: defaultEmptyString(domain.vatNumber),
    }),
};
