import React, { CSSProperties } from "react";
import { Button, Message } from "semantic-ui-react";
import { Link } from "react-router-dom";
import {TFunction} from "i18next";
import {Trans, useTranslation} from "react-i18next";
import {TCookieConsentData} from "../../models/cookieConsent/TCookieConsentData";
import {useCookieConsentContext} from "./CookieConsentProvider";

const style: CSSProperties = {
    position: "fixed",
    bottom: 0,
    width: "100vw",
    zIndex: 1,
};

export const CookieConsent: React.FC = () => {
    const context = useCookieConsentContext();

    const acceptAllCookies = () => {
        const consent: TCookieConsentData = {
            consentAsked: true,
            mandatoryAccepted: true,
            analyticsAccepted: true,
        };
        context?.setConsent(consent);
    };

    const acceptMandatory = () => {
      const consent: TCookieConsentData = {
          consentAsked: true,
          mandatoryAccepted: true,
          analyticsAccepted: false,
      };
      context?.setConsent(consent);
    };

    const { cookiesDescription, cookiesReadMore, acceptMandatoryText, acceptAll, dataDescription } = useTextProps(useTranslation().t);

    if (!context || context.data.consentAsked) {
        return null;
    }

    return (
        <Message info style={style} className="cookie-consent">
            <p>{cookiesDescription}</p>
            <p>{dataDescription}</p>
                <Trans i18nKey="cookie_consent.page.what_we_collect">
                    Kävijädataa (kävijämäärät, palvelussa liikkuminen, toimintojen käyttö) keräämme ja analysoimme <strong>Google
                    Analytics</strong>in avulla. Lisätietoja Googlen evästeistä löydät täältä: <a
                    href="https://policies.google.com/technologies/cookies" target="_blank" rel={"noreferrer"}>Googlen
                    evästekäytäntö.</a> Evästetiedot auttavat meitä parantamaan palvelumme toimivuutta ja käytettävyyttä.
                </Trans>
            <p>
                <Button as={Link} to="/cookies" primary type="button" content={cookiesReadMore} />
                <Button primary type="button" content={acceptMandatoryText} onClick={acceptMandatory} />
                <Button primary type="button" content={acceptAll} onClick={acceptAllCookies} />
            </p>
        </Message>
    );
};

const useTextProps = (t: TFunction) => {
    return {
        cookiesDescription: t("cookie_consent.description"),
        dataDescription: t("cookie_consent.page.why_we_collect"),
        cookiesReadMore: t("cookie_consent.read_more"),
        acceptMandatoryText: t("cookie_consent.accept_mandatory"),
        acceptAll: t("cookie_consent.accept_all"),
    };
};
