import { CompanyMapper } from '../../@api/mappers/companyMapper';
import { PersonMapper } from '../../@api/mappers/personMapper';
import { IUserBasicInfoDTO } from '../../@api/dtos/IUserBasicInfoDTO';
import { IUserDTO } from '../../@api/dtos/IUserDTO';
import { UserMapper } from '../../@api/mappers/userMapper';
import { IApiResponse } from '../../sg-core/models/IApiResponse';
import { TResult, makeSuccess } from '../../sg-core/utils/Result';
import { TulsuEndpoint } from '../../@api/endpoints';
import { ISgApiService } from '../../@api/services/ApiService';
import { IUser } from "../interfaces/IUser";
import { makeFailure } from '../../sg-core/utils/Result';
import { IUserBasicInfo } from '../models/IUserBasicInfo';
import { ReturnedPromiseResolvedType } from "../../sg-core/utils/type.helpers";
import {TLoginResponse} from "../models/TLoginResponse";

interface IRegistrationData {
    email: string;
    password: string;
    firstName: string;
    surname: string;
}

export interface IUserService {
    logIn: (username: string, password: string, remember?: boolean) => Promise<TLoginResponse | undefined>;
    logInWithGuid: (username: string, passwordGuid: string, remember?: boolean) => Promise<TLoginResponse | undefined>
    logOut: () => Promise<void>;
    register: (registrationData: IRegistrationData) => Promise<TResult<string>>;
    activateAccount: (activationGuid: string, activationCode: string) => Promise<TResult<TLoginResponse>>;
    validatePassword: (password: string) => Promise<TResult<boolean>>;
    changeUserPassword: (userId: number, oldPassword: string, newPassword: string) => Promise<TResult<true>>;
    getUserInfo: (userId: number) => Promise<TResult<IUserBasicInfo>>;
    getUserById: (userId: number) => Promise<TResult<IUser>>;

    requestPasswordReset: (email: string) => Promise<TResult<{ verificationGuid: string; }>>;
    verifyPasswordReset: (verificationGuid: string, verificationCode: string, newPassword: string) => Promise<TResult<{ user: IUser, sid: string }>>;
}

// Default implementation

export const createUserService = (apiService: ISgApiService): IUserService => {

    const ACTION_LOGIN = "kasittele_kirjautuminen_anonyymi";
    const ACTION_REGISTER = "laheta_uusi_kayttaja_sahkoposti_anonyymi";
    const ACTION_ACTIVATE_ACCOUNT = "kasittele_uusi_kayttaja_sahkoposti_anonyymi";
    const ACTION_GET_BASIC_INFO = "hae_kayttajan_perustiedot";
    const ACTION_CHANGE_PASSWORD = "vaihda_salasana";
    const ACTION_VALIDATE_PASSWORD = "verifioi_salasana_anonyymi";

    const ACTION_REQUEST_PASSWORD_RESET = "laheta_kirjautumis_sahkoposti_anonyymi";
    const ACTION_VERIFY_PASSWORD_RESET = "kasittele_kirjautumis_sahkoposti_anonyymi";


    const logIn = async (loginData: { username: string, password?: string, passwordGuid?: string }): Promise<{ user: IUser, sid: string; passwordGuid: string } | undefined> => {
        if (!loginData.password && !loginData.passwordGuid) {
            return undefined;
        }
        const payload = {
            kayttajatunnus: loginData.username,
            salasana: loginData.password,
            salasana_guid: loginData.passwordGuid
        };

        const result = await apiService.sendAction<IApiResponse & { kayttaja: IUserDTO, sid: string }>(TulsuEndpoint, ACTION_LOGIN, payload, true);

        if (result.isSuccess) {
            return {
                user: UserMapper.apiToDomain(result.value.kayttaja),
                sid: result.value.sid,
                passwordGuid: result.value.kayttaja.salasana_guid || ""
            };
        }

        return undefined;
    };


    const logInWithPassword = async (username: string, password: string): Promise<ReturnedPromiseResolvedType<typeof logIn>> => {
        return logIn({ username, password });
    };

    const logInWithGuid = async (username: string, passwordGuid: string): Promise<ReturnedPromiseResolvedType<typeof logIn>> => {
        return logIn({ username, passwordGuid });
    };

    const logOut = async () => {
        // Do nothing :D
    };

    const register = async (data: IRegistrationData): Promise<TResult<string>> => {
        const payload = {
            //  kayttaja: {
            etunimi: data.firstName,
            sukunimi: data.surname,
            meiliosoite: data.email,
            salasana: data.password,
            //   }
        };

        try {
            const result = await apiService.sendAction<IApiResponse & { sanoma_guid: string }>(TulsuEndpoint, ACTION_REGISTER, payload, true);

            if (result.isSuccess) {
                const verificationToken = result.value.sanoma_guid;
                return makeSuccess(verificationToken);
            } else {
                return makeFailure(result.error);
            }
        } catch (apiError) {
            return makeFailure(apiError);
        }
    };

    const activateAccount = async (activationGuid: string, activationCode: string): Promise<TResult<{ user: IUser, sid: string, passwordGuid: string }>> => {
        const payload = {
            sanoma_guid: activationGuid,
            meilikoodi: activationCode,
        };

        try {
            const result = await apiService.sendAction<IApiResponse & { kayttajatunnus: string, salasana_guid: string }>(TulsuEndpoint, ACTION_ACTIVATE_ACCOUNT, payload, true);

            if (result.isSuccess) {
                const loginResult = await logInWithGuid(result.value.kayttajatunnus, result.value.salasana_guid);
                if (loginResult?.user && loginResult?.sid) {
                    return makeSuccess(loginResult)
                }
                return makeFailure(new Error("Failed to login"))
            } else {
                return makeFailure(result.error);
            }
        } catch (apiError) {
            return makeFailure(apiError);
        }
    };


    const validatePassword = async (password: string): Promise<TResult<boolean>> => {
        const payload = {
            salasana: password,
        };

        const result = await apiService.sendAction<IApiResponse & {}>(TulsuEndpoint, ACTION_VALIDATE_PASSWORD, payload, true);

        if (result.isSuccess) {
            return makeSuccess(true);
        }
        return makeFailure(result.error);
    };


    const requestPasswordReset = async (email: string): Promise<TResult<{ verificationGuid: string }>> => {
        const payload = {
            meiliosoite: email,
        };

        const result = await apiService.sendAction<IApiResponse & { sanoma_guid: string }>(TulsuEndpoint, ACTION_REQUEST_PASSWORD_RESET, payload, true);

        if (result.isSuccess) {
            return makeSuccess({ verificationGuid: result.value.sanoma_guid });
        }
        return makeFailure(result.error);
    };

    const verifyPasswordReset = async (verificationGuid: string, verificationCode: string, newPassword: string): Promise<TResult<{ user: IUser, sid: string }>> => {
        const payload = {
            sanoma_guid: verificationGuid,
            meilikoodi: verificationCode,
            uusi_salasana: newPassword
        };

        const result = await apiService.sendAction<IApiResponse & { kayttajatunnus: string, salasana_guid: string }>(TulsuEndpoint, ACTION_VERIFY_PASSWORD_RESET, payload, true);

        if (result.isSuccess) {
            const { kayttajatunnus: username, salasana_guid: passwordGuid } = result.value
            const loginResult = await logInWithGuid(username, passwordGuid);
            if (loginResult?.user) {
                return makeSuccess(loginResult);
            }
            return makeFailure(new Error("Failed to login"));
        }
        return makeFailure(result.error);
    };


    const changeUserPassword = async (userId: number, oldPassword: string, newPassword: string): Promise<TResult<true>> => {
        const payload = {
            kayttaja: { id: userId },
            vanha_salasana: oldPassword,
            uusi_salasana: newPassword,
        };

        try {
            const result = await apiService.sendAction<IApiResponse>(TulsuEndpoint, ACTION_CHANGE_PASSWORD, payload);

            if (result.isSuccess) {
                return makeSuccess(true);
            } else {
                return makeFailure(result.error);
            }
        } catch (apiError) {
            return makeFailure(apiError);
        }
    };

    const getUserInfo = async (id: number): Promise<TResult<IUserBasicInfo>> => {
        const payload = {
            kayttaja: {
                id,
            },
        };

        try {
            const result = await apiService.sendAction<IUserBasicInfoDTO>(TulsuEndpoint, ACTION_GET_BASIC_INFO, payload);

            if (result.isSuccess) {
                return makeSuccess({
                    user: UserMapper.apiToDomain(result.value.kayttaja),
                    persons: (result.value.henkilot ?? []).map(PersonMapper.apiToDomain),
                    companies: (result.value.yritykset ?? []).map(CompanyMapper.apiToDomain),
                });
            } else {
                return makeFailure(result.error);
            }
        } catch (apiError) {
            return makeFailure(apiError);
        }
    };

    return {
        logIn: logInWithPassword,
        logInWithGuid,
        logOut,
        register,
        activateAccount,
        validatePassword,
        changeUserPassword,
        getUserInfo,
        getUserById: async (id) => {
            const action = "hae_kayttaja";
            const payload = { kayttaja: {id} };

            const response = await apiService.sendAction<{ kayttaja?: IUser }>(TulsuEndpoint, action, payload);

            if (response.isFailure) {
                return makeFailure(response.error);
            }

            if (response.value.kayttaja) {
                return makeSuccess(UserMapper.apiToDomain(response.value.kayttaja));
            } else {
                return makeFailure(new Error("not found"));
            }
        },

        requestPasswordReset,
        verifyPasswordReset
    }
};
