/*
 * The purpose of this module is to abstract logging away from the standard console object. This makes it easier to plug
 * in a proper logging framework later, if required.
 * - Mikael S.
 */

import {isDate} from "date-fns";

export type TLogLevel =
    "debug" |
    "error" |
    "info"  |
    "log"   |
    "trace" |
    "warn";


type TLogArgs = Record<string, any>;

type TLoggingFunction = (message: string, args?: TLogArgs) => void;

/**
 * Generic abstract logger. Supports structured logging via interpolation. Use {} to mark parts of the message to interpolate
 */
type TLogger = {
    log: (level: TLogLevel, message: string, args?: TLogArgs) => void;
    info: TLoggingFunction;
    error: TLoggingFunction;
    debug: TLoggingFunction;
    trace: TLoggingFunction;
    warn: TLoggingFunction;
};

// For now, we use console
const logger = console;

/**
 * Stringifies a parameter value that will be interpolated into the log message
 * @param value The value to stringify
 * @param objectsToStringOnly Should values with type "object" be stringified only with .toString()?
 * @return An interpolated version of the value. Strings are returned as is, numbers are returned in string format, dates
 * are returned as ISO strings, booleans are returned as {TRUE} of {FALSE}, arrays are returned with all their values
 * stringified (except objects, which are simply toStringed).
 * @example
 * // returns "{TRUE}"
 * stringifyValue(true);
 * @example
 * // returns "2021-08-20T07:53:00.123Z"
 * stringifyValue(new Date());
 * @example
 * // returns '["text1", "15", "[object Object]"]'
 * stringifyValue(["text1", 15, {}]);
 * @example
 * // returns "{EMPTY}"
 * stringifyValue(null);
 */
const stringifyValue = (value: any, objectsToStringOnly: boolean = false): string => {
    if (value === null || value === undefined) {
        return "{EMPTY}";
    }
    switch (typeof(value)) {
        case "string":
            return value;
        case "number":
            return value.toString();
        case "boolean":
            return value ? '{TRUE}' : '{FALSE}';
        case "object":
            if (objectsToStringOnly) {
                return value.toString();
            }
            if (isDate(value)) {
                return value.toISOString();
            }
            if (Array.isArray(value)) {
                return '["' + value.map(v => stringifyValue(v, true)).join('", "') + '"]';
            }
    }
    return value.toString();
};

/**
 * Structured logging interpolator
 * @param message The base log message
 * @param args Interpolation arguments
 * @example
 * const msg = interpolate("Hello {name}", { name: "World" });
 * console.log(msg); // "Hello World"
 */
const interpolate = (message: string, args: TLogArgs): string => {
    const curlify = (str: string) => `{${str}}`;

    let interpolatedMessage = message;
    for (const key in args) {
        interpolatedMessage = interpolatedMessage.replace(curlify(key), stringifyValue(args[key]));
    }
    return interpolatedMessage;
};

const createLogger = (): TLogger => {
    const loggerFunction = (level: TLogLevel, message: string, args: TLogArgs = {}) => {
        if (logger) {
            try {
                if (logger[level]) {
                    logger[level](interpolate(message, args));
                } else {
                    logger.error("Error in log command. Tried to log to level '{level}' but such level does not exist. Original log message: '{message}'. Params: {params}", {
                        level,
                        message,
                        params: JSON.stringify(args)
                    });
                }
            } catch (e) {
                // ignore
            }
        }
    };

    const createShorthandFn = (level: TLogLevel): TLoggingFunction => (message, args) => {
        loggerFunction(level, message, args);
    };

    return {
        log: loggerFunction,
        debug: createShorthandFn("debug"),
        info: createShorthandFn("info"),
        trace: createShorthandFn("trace"),
        warn: createShorthandFn("warn"),
        error: createShorthandFn("error"),
    };
};

export const TulsuLogger: TLogger = createLogger();
