import React from "react";
import { useAuthContext } from "../AuthenticationProvider";
import { LoggedInGuard } from "../components/guards/LoggedInGuard";
import { Segment, Icon } from "semantic-ui-react";
import { RouteProps, Route } from "react-router-dom";
import LoginView from "../views/LoginView";
import { SelectedCompanyGuard } from "../../@company/components/guards/SelectedCompanyGuard";

export const AuthenticatedRoute: React.FC<RouteProps> = (props) => {

    const { loggedIn, loggingIn, initialized } = useAuthContext();

    if (loggedIn) {
        return (
            <LoggedInGuard>
                <SelectedCompanyGuard>
                    <Route {...props} />
                </SelectedCompanyGuard>
            </LoggedInGuard>
        );
    }

    if (!initialized && loggingIn) {
        return (
            <Segment placeholder textAlign="center" padded="very" className="fullpage_loading_segment" >
                <p><Icon name="circle notched" loading /> Kirjaudutaan</p>
            </Segment>
        );
    }

    return (
        <LoginView />
    );
};
