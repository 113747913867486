import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { addErrorBoundaryToRoutes, IRoute, renderRoute } from "./navUtils";
import { Segment } from "semantic-ui-react";
import { CookieInfoView } from "../view/CookieInfo";

const OnboardingRouting = React.lazy(() => import(/* webpackChunkName: "onboarding" */"../../@onboarding/navigation/OnboardingRouting"));
const TulsuRouting = React.lazy(() => import(/* webpackChunkName: "tulsu" */"../../@tulsu/navigation/TulsuRouting"));
const TulityotRouting = React.lazy(() => import(/* webpackChunkName: "permits" */"../../@tulityot/navigation/TulityotRouting"));
const PersonRouting = React.lazy(() => import(/* webpackChunkName: "people" */"../../@persons/navigation/PersonRouting"));
const FrontPageView = React.lazy(() => import(/* webpackChunkName: "frontpage" */"../../@frontpage/views/Frontpage"));
const SettingsView = React.lazy(() => import(/* webpackChunkName: "settings" */"../../@settings/views/SettingsView"));
const ForgotPasswordPage = React.lazy(() => import(/* webpackChunkName: "forgotPasswordPage" */"../../@authentication/views/ForgotPasswordPage"));
const ShopRouting = React.lazy(() => import(/* webpackChunkName: "shop" */"../../@shop/views/ShopRoutes"));

const APP_ROUTES: IRoute[] = addErrorBoundaryToRoutes([{
    path: "/",
    render: () => <FrontPageView />,
    exact: true,
}, {
    path: "/cookies",
    render: () => <CookieInfoView />,
    exact: true,
}, {
    path: "/kirjaudu",
    render: () => <Redirect to="/" />,
    requireAuthentication: true,
}, {
    path: "/unohtunut-salasana",
    render: () => <ForgotPasswordPage />,
}, {
    path: "/rekisteroidy",
    render: () => <OnboardingRouting />,
}, {
    path: "/suunnitelmat",
    render: () => <TulsuRouting />,
    requireAuthentication: true,
}, {
    path: "/luvat",
    render: () => <TulityotRouting />,
}, {
    path: "/henkilot",
    render: () => <PersonRouting />,
    requireAuthentication: true,
}, {
    path: "/asetukset",
    render: () => <SettingsView />,
    requireAuthentication: true,
}, {
    path: "/kauppa",
    render: () => <ShopRouting />,
    requireAuthentication: true,
}]);

interface ITopLevelRoutingProps {
    authRouteComponent: React.FC;
}

export const TopLevelRouting: React.FC<ITopLevelRoutingProps> = ({ authRouteComponent }) => {
    const render = (route: IRoute) => renderRoute(route, authRouteComponent);

    return (
        <Suspense fallback={<Segment className="fullpage_loading_segment" loading padded="very" />}>
            <Switch>
                {APP_ROUTES.map(render)}
                <Route>
                    <Redirect to="/" />
                </Route>
            </Switch>
        </Suspense>
    );
};
