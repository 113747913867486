import React, { useEffect, Fragment, Suspense } from "react";
import { Menu, Icon, Dropdown, Divider } from "semantic-ui-react";
import { NavLink, useLocation, Link } from "react-router-dom";
import { useAuthContext } from "../../@authentication/AuthenticationProvider";
import { GlobalCompanySelector } from "../../@company/components/selectors/GlobalCompanySelector";
import { useTranslation } from "react-i18next";
import { OnlyForMaintainers } from "../../@authentication/components/guards/OnlyFor";
import { useBool } from "../../sg-hooks/useBool";
import ErrorBoundary from "../components/error/ErrorBoundary";
import "./topnavStyles.css";
import {TopNavLanguagePicker} from "./TopNavLanguagePicker";

const useTextProps = () => {
  const { t } = useTranslation();

  return {
    site_title: t("page_title.site_name"),
    frontpage: t("page_title.frontpage"),
    plans: t("page_title.plans"),
    permits: t("page_title.permits"),
    persons: t("page_title.persons"),
    settings: t("page_title.settings"),
    logOut: t("page_title.logOut"),
    feedback: t("page_title.feedback"),
    language: t("languages.language"),
  };
};

export const TopNav: React.FC = () => {
  const texts = useTextProps();

  const { loggedIn, user, logOut } = useAuthContext();

  const { pathname } = useLocation();

  const [isMobileMenuOpen, openMobileMenu, closeMobileMenu] = useBool(false);

  // Prevent scroll when menu is open
  useEffect(() => {
    document.body.style.overflow = isMobileMenuOpen ? "hidden" : "unset"
  }, [isMobileMenuOpen]);

  useEffect(() => {
    closeMobileMenu()
  }, [pathname, closeMobileMenu]);

  return (
    <Menu
      fluid
      inverted
      color={"blue"}
      className="main-navigation"
      fixed="top"
    >
      <Menu color="red" inverted className="site-logo">
        <Menu.Item header as={Link} to="/">
          <Icon name="fire" /> {texts.site_title}
        </Menu.Item>
      </Menu>

      <Fragment>
        {/* MOBILE */}
        <Menu inverted color={"blue"} className="topnav-mobile right only_mobile">
          {loggedIn && (
            <Fragment>

              <Menu.Item onClick={isMobileMenuOpen ? closeMobileMenu : openMobileMenu}>
                <Icon name={isMobileMenuOpen ? "times" : "bars"} />
              </Menu.Item>

              <div className={`expandable_mobile_menu${isMobileMenuOpen ? " expanded" : ""}`}>

                <Divider hidden />

                <Menu.Item>
                  <ErrorBoundary>
                    <Suspense fallback={<Icon name="circle notched" loading />}>
                      <GlobalCompanySelector onSelectCompany={closeMobileMenu} />
                    </Suspense>
                  </ErrorBoundary>
                </Menu.Item>

                <Menu.Item
                  as={NavLink}
                  to="/"
                  icon="home"
                  content={texts.frontpage}
                />
                <Menu.Item
                  as={NavLink}
                  to="/suunnitelmat"
                  icon="file outline"
                  content={texts.plans}
                />
                <Menu.Item
                  as={NavLink}
                  to="/luvat"
                  icon="fire"
                  content={texts.permits}
                />

                <Suspense
                  fallback={
                    <Menu.Item>
                      <Icon name="circle notched" loading />
                    </Menu.Item>
                  }
                >
                  <OnlyForMaintainers>
                    <Menu.Item
                      as={NavLink}
                      to="/henkilot"
                      icon="users"
                      content={texts.persons}
                    />
                  </OnlyForMaintainers>
                </Suspense>

                <Menu.Item
                  as="a"
                  target="_blank"
                  icon="comment"
                  href="https://forms.office.com/Pages/ResponsePage.aspx?id=n10dzYQv00eXFo2i31_YasuyB_RKEBBPtqW3RbdflERUQTZLRE5MOVVVOE5TMkZQNTJTUTNMWDJDSyQlQCN0PWcu"
                  content={texts.feedback}
                />

                <Menu.Item
                  as={NavLink}
                  to="/asetukset"
                  icon="settings"
                  content={texts.settings}
                />

                <TopNavLanguagePicker />

                {user && (
                  <Fragment>
                    <Menu.Item
                      icon="user"
                      content={`${user.firstName} ${user.surname}`}
                    />
                    <Menu.Item
                      icon="sign-out"
                      content={texts.logOut}
                      onClick={logOut}
                    />
                  </Fragment>
                )}



              </div>
            </Fragment>
          )}
        </Menu>


        {/* DESKTOP */}
        <div className="topnav-desktop-force-flex topnav-desktop">
          {loggedIn && (
            <Fragment>
              <Menu.Item className="company-menu">
                <ErrorBoundary>
                  <Suspense fallback={<Icon name="circle notched" loading />}>
                    <GlobalCompanySelector />
                  </Suspense>
                </ErrorBoundary>
              </Menu.Item>

              <div className="right menu">

                <Menu.Item
                  as={NavLink}
                  to="/"
                  icon="home"
                  exact
                  content={texts.frontpage}
                />
                <Menu.Item
                  as={NavLink}
                  to="/suunnitelmat"
                  icon="file outline"
                  content={texts.plans}
                />
                <Menu.Item
                  as={NavLink}
                  to="/luvat"
                  icon="fire"
                  content={texts.permits}
                />

                <Suspense
                  fallback={
                    <Menu.Item>
                      <Icon name="circle notched" loading />
                    </Menu.Item>
                  }
                >
                  <OnlyForMaintainers>
                    <Menu.Item
                      as={NavLink}
                      to="/henkilot"
                      icon="users"
                      content={texts.persons}
                    />
                  </OnlyForMaintainers>
                </Suspense>

                <Menu.Item
                  as="a"
                  target="_blank"
                  icon="comment"
                  href="https://forms.office.com/Pages/ResponsePage.aspx?id=n10dzYQv00eXFo2i31_YasuyB_RKEBBPtqW3RbdflERUQTZLRE5MOVVVOE5TMkZQNTJTUTNMWDJDSyQlQCN0PWcu"
                  content={texts.feedback}
                />

                <Dropdown
                  item
                  icon="settings"
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      as={Link}
                      to="/asetukset"
                      icon="settings"
                      content={texts.settings}
                    />

                    { user && (
                      <Fragment>
                        <Dropdown.Header content={`${user.firstName} ${user.surname}`} />
                        <Dropdown.Item
                          icon="sign-out"
                          text={texts.logOut}
                          onClick={logOut}
                        />
                      </Fragment>
                    ) }
                  </Dropdown.Menu>
                </Dropdown>

                <TopNavLanguagePicker />

              </div>
            </Fragment>
          )}
        </div>
      </Fragment>


    </Menu >
  );
};
