import { IUser } from "./IUser";

export interface IAuthContext {
    // Properties
    initialized: boolean;
    loggingIn: boolean;
    loginError?: string;
    loggedIn: boolean;
    user?: IUser;
    token?: string;
    currentCompanyId?: number;

    // Methods
    logIn: (username: string, password: string, remember?: boolean) => void;

    /**
     * Logs in with the specified user data
     * @param user The user object
     * @param sid Active SID
     * @param passwordGuid Password guid to remember the login with
     * @param remember If boolean, remembers for 30 days, if number, remembers login for given amount of days
     */
    loginWithUser: (user: IUser, sid: string, passwordGuid: string, remember?: boolean | number) => void;
    loginWithCookie: () => void;

    logOut: () => void;
    initialize: () => void;
}

const noop = () => undefined;

export const initialAuthContext: IAuthContext = {
    initialized: false,
    loggedIn: false,
    loggingIn: false,
    logIn: noop,
    loginWithUser: noop,
    loginWithCookie: noop,
    logOut: noop,
    initialize: noop,
};
