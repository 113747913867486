import React, {createContext, useContext, useMemo, useState} from "react";
import {TCookieConsentData} from "../../models/cookieConsent/TCookieConsentData";
import {getConsentCookie, setConsentCookie} from "./cookieConsentHelpers";

type TCookieConsentContextData = {
  data: TCookieConsentData;
  setConsent: (value: TCookieConsentData) => void;
};

const context = createContext<TCookieConsentContextData | undefined>(undefined);
const Provider = context.Provider;

export const useCookieConsentContext = () => useContext(context);

export const CookieConsentProvider: React.FC = ({ children }) => {
    const initial = getConsentCookie();
    const [consent, setConsent] = useState<TCookieConsentData>(initial);

    const setAndStoreConsent = (consent: TCookieConsentData) => {
        setConsent(consent);
        setConsentCookie(consent);
    };

    const providerValue = useMemo((): TCookieConsentContextData => {
        return {
            data: consent,
            setConsent: setAndStoreConsent,
        };
    }, [consent, setConsent]);

    return <Provider value={providerValue}>{children}</Provider>;
};
