import {Dropdown} from "semantic-ui-react";
import React from "react";
import {useTranslation} from "react-i18next";
import {useLanguageSwitcher} from "../hooks/useLanguageSwitcher";

export const GlobalLanguagePicker: React.FC = () => {
    const language = useLanguageSwitcher();
    const { t } = useTranslation();

    const current = language.options.find(x => x.value === language.currentLanguage);

    const currentLanguageString = `${t("languages.language")} (${t(`languages.${current?.text}`)})`;

    return (
        <Dropdown text={currentLanguageString}>
            <Dropdown.Menu>
                { language.options.map((lng) => (
                    <Dropdown.Item
                        key={lng.value}
                        icon="settings"
                        content={t(`languages.${lng.text}`)}
                        active={lng.value === language.currentLanguage}
                        onClick={() => language.setLanguage(lng.value)}
                    />
                )) }
            </Dropdown.Menu>
        </Dropdown>
    );
};
