import React, { useEffect } from "react";
import { CreateCompanyHook } from "../../hooks/useCreateCompany";
import { ICompany } from "../../models/ICompany";
import { useTranslation } from "react-i18next";
import { CompanyCreateError } from "../../errors/companyCreateErrors";
import {CompanyForm} from "./CompanyForm";

interface ICreateFormProps {
    createHook: CreateCompanyHook;
    onCreated?: (company: ICompany) => void;
    onError?: (message: string) => void;
}

const useTextProps = () => {
    const { t } = useTranslation();
    return {
        companyName: t("company.fields.name"),
        save: t("generic.save"),
        nameInUse: t("company.actions.create.company_name_taken"),
        verify: t("generic.error.check_information"),
    };
};

export const CompanyCreateForm: React.FC<ICreateFormProps> = ({ createHook, onCreated, onError }) => {
    const texts = useTextProps();

    const {
        createError,
        createdCompany,
    } = createHook();

    useEffect(() => {
        if (createdCompany && onCreated) {
            onCreated(createdCompany);
        }
    }, [createdCompany]);

    useEffect(() => {
        if (createError && onError) {
            onError(createError === CompanyCreateError.CompanyNameInUse
                ? texts.nameInUse
                : createError);
        }
    }, [createError]);

    return (
        <CompanyForm
            onSave={onCreated}
        />
    );
};
