import { useSWRCache } from '../../@common/swr/useSWRCache';
import { getSgApiService } from '../../@api/services/ApiService';
import { createUserService } from '../services/UserService';
import { IAuthContext } from "../interfaces/IAuthContext";
import { useReducer, Reducer, useMemo, useEffect } from "react";
import { IUser } from "../interfaces/IUser";
import { useTranslation } from "react-i18next";
import { useCookie } from '../../sg-hooks/useCookie';
import { makeFailure, makeSuccess } from '../../sg-core/utils/Result';
import { CookieAttributes } from "js-cookie";

export type AuthenticationHook = () => IAuthContext;

// Default authentication hook implementation

interface IState {
    initializing: boolean;
    initialized: boolean;
    loggedIn: boolean;
    loggingIn: boolean;
    loginError?: string;
    user?: IUser;
    token?: string;
}

enum EAction {
    InitializationStarted,
    InitializationComplete,
    StartLogin,
    SuccessLogin,
    FailureLogin,
    FailureCookieLogin,
    Logout,
}

type TAction
    = { type: EAction.Logout | EAction.StartLogin | EAction.InitializationComplete | EAction.InitializationStarted | EAction.FailureCookieLogin }
    | { type: EAction.FailureLogin, error: string }
    | { type: EAction.SuccessLogin, user: IUser, token: string };

const initialState: IState = {
    initializing: false,
    initialized: false,
    loggedIn: false,
    loggingIn: false,
};

const reducer: Reducer<IState, TAction> = (state, action) => {

    const set = (data: Partial<IState>) => ({ ...state, ...data });

    switch (action.type) {
        case EAction.InitializationStarted: {
            return set({ initializing: true });
        }
        case EAction.InitializationComplete: {
            return set({ initialized: true, initializing: false });
        }
        case EAction.StartLogin: {
            return set({ loggedIn: false, loggingIn: true, user: undefined, loginError: undefined });
        }
        case EAction.SuccessLogin: {
            return set({ loggedIn: true, loggingIn: false, user: action.user, token: action.token });
        }
        case EAction.FailureLogin: {
            return set({ loggedIn: false, loggingIn: false, loginError: action.error });
        }
        case EAction.FailureCookieLogin: {
            return set({ loggedIn: false, loggingIn: false, loginError: undefined });
        }
        case EAction.Logout: {
            return set({ loggedIn: false, loggingIn: false, loginError: undefined, user: undefined });
        }
        default: {
            return state;
        }
    }
};

const useTextProps = () => {
    const { t } = useTranslation();
    return {
        invalidCred: t("Tarkista käyttäjätunnus ja/tai salasana"),
    };
};

/**
 * Default attributes for the authentication cookie
 */
const AUTH_COOKIE_ATTRIBUTES: CookieAttributes = {
    path: "/",
    /**
     * Never ever send over insecure connections
     */
    secure: true,

    /**
     * Sadly, this has to be like this since most of our API requests are cross-origin
     */
    sameSite: "none",
};

export const useAuthentication: AuthenticationHook = () => {
    const texts = useTextProps();
    const [state, dispatch] = useReducer(reducer, initialState);

    const { cache } = useSWRCache();

    const authCookie = useCookie<{ username: string; passwordGuid: string }>("tulsu:auth", AUTH_COOKIE_ATTRIBUTES);

    useEffect(() => {
        if (state.loginError) {
            authCookie.delete();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loginError]);

    return useMemo(() => {

        const authService = createUserService(getSgApiService(state.token));

        const persistLogin = (remember: boolean | number, username: string, passwordGuid: string) => {
            // Choose the cookie expiration time. If the remember-flag is set, this will be 30 days. Otherwise
            // make the cookie a session cookie which will disappear once the browser is closed.
            const cookieExpiration = remember ? (typeof(remember) == "number" ? remember : 30) : undefined;

            authCookie.delete();
            authCookie.set({ username, passwordGuid }, {
                expires: cookieExpiration,
            });
        };

        const logIn = async (username: string, password: string, remember: boolean = false) => {
            dispatch({ type: EAction.StartLogin });
            try {
                const result = await authService.logIn(username, password, true);

                if (result) {
                    dispatch({ type: EAction.SuccessLogin, user: result.user, token: result.sid });

                    persistLogin(remember, result.user.username, result.passwordGuid);
                } else {
                    dispatch({ type: EAction.FailureLogin, error: texts.invalidCred });
                }
            } catch (loginError) {
                dispatch({ type: EAction.FailureLogin, error: loginError.message });
            }
        };

        const loginWithUser = (user: IUser, sid: string, passwordGuid: string, remember: boolean | number = false) => {
            dispatch({ type: EAction.SuccessLogin, user, token: sid });
            persistLogin(remember, user.username, passwordGuid);
        };

        const loginWithCookie = async () => {
            const authInfo = authCookie.get();
            if (authInfo.isSuccess) {
                try {
                    const { username, passwordGuid } = authInfo.value
                    const result = await authService.logInWithGuid(username, passwordGuid);

                    if (result) {
                        dispatch({ type: EAction.SuccessLogin, user: result.user, token: result.sid });
                        persistLogin(true, result.user.username, result.passwordGuid);
                        return makeSuccess({ user: result.user, token: result.sid })
                    } else {
                        dispatch({ type: EAction.FailureCookieLogin });
                        return makeFailure(new Error(texts.invalidCred))
                    }
                } catch (loginError) {
                    dispatch({ type: EAction.FailureCookieLogin });
                    return makeFailure(loginError);
                }
            }
        };


        const logOut = async () => {
            dispatch({ type: EAction.Logout });
            cache.clear();
            authCookie.delete();
        };

        const initialize = async () => {
            if (!state.initializing && !state.initialized) {
                dispatch({ type: EAction.InitializationStarted });
                await loginWithCookie();
                dispatch({ type: EAction.InitializationComplete });
            }
        };

        return {
            logIn,
            loginWithUser,
            loginWithCookie,
            logOut,
            loggedIn: state.loggedIn,
            loggingIn: state.loggingIn,
            loginError: state.loginError,
            initialized: state.initialized,
            user: state.user,
            token: state.token,
            initialize,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);
};
