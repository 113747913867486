import React, { useMemo } from "react";
import { DropdownProps, Dropdown, DropdownItemProps } from "semantic-ui-react";
import { ICompany } from "../../models/ICompany";

interface ICompanySelectorProps extends DropdownProps {
    companies: ICompany[];
    selectedId?: number;
    onCompanySelect?: (id: number) => void;
    appendOptions?: DropdownItemProps[];
}

export const CompanySelector: React.FC<ICompanySelectorProps> = ({ companies, selectedId, onCompanySelect, appendOptions, ...dropdownProps }) => {

    const options = useMemo(() => {
        const companyOptions = companies.map((company): DropdownItemProps => ({
            value: company.id,
            text: company.name,
        }));

        if (appendOptions?.length) {
            appendOptions.forEach((i) => companyOptions.push(i));
        }

        return companyOptions;
    }, [companies, appendOptions]);

    const changed: DropdownProps["onChange"] = (_, { value }) => {
        if (onCompanySelect) {
            onCompanySelect(parseInt(value as string, 10));
        }
    };

    return (
        <Dropdown
            options={options}
            value={selectedId}
            {...dropdownProps}
            onChange={changed}
            search
        />
    );
};
