import React, { useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";

interface IPageScrollLocationState {
    noScrollTop: boolean;
}

export const ControllerPageScrollTriggered: React.FC<{ trigger?: number } & ScrollOptions> = ({ children, trigger, ...scrollOptions }) => {
    const wrapperElement = useRef<HTMLDivElement>(null);

    useEffect(() => {
        try {
            if (!window.scrollTo) {
                return;
            }
            wrapperElement.current && window.scrollTo({
                left: 0,
                top: wrapperElement.current.scrollTop,
                behavior: "smooth",
                ...scrollOptions
            });
        } catch (e) {
            return;
        }

    }, [trigger]);

    return (
        <div ref={wrapperElement}>
            {children}
        </div>
    );
};

const ControlledPageScroll: React.FC = ({ children }) => {
    const location = useLocation<IPageScrollLocationState>()
    const history = useHistory()

    const wrapperElement = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!window.scrollTo) {
            return;
        }
        let isNavigatingBackwards = history.action === "POP";
        if (!isNavigatingBackwards && location.state?.noScrollTop !== true) {
            wrapperElement.current && window.scrollTo({
                left: 0,
                top: wrapperElement.current.scrollTop,
                behavior: "smooth",
            });
        }

    }, [location, history]);

    return (
        <div ref={wrapperElement}>{children}</div>
    );
};


export default ControlledPageScroll;
