import { PersonMapper } from '../../@api/mappers/personMapper';
import { IPersonDTO } from '../../@api/dtos/IPersonDTO';
import { IPerson } from '../../@common/models/Person';
import { CompanyCreateError } from '../errors/companyCreateErrors';
import { ICompanyDTO } from '../../@api/dtos/ICompanyDTO';
import { CompanyMapper } from '../../@api/mappers/companyMapper';
import { ICompany } from '../models/ICompany';
import { IApiResponse } from '../../sg-core/models/IApiResponse';
import { TulsuEndpoint } from '../../@api/endpoints';
import { ISgApiService } from '../../@api/services/ApiService';
import { TResult, makeFailure, makeSuccess } from "../../sg-core/utils/Result";

type CompanyCreationData = ICompany;
type CompanyCreationResult = {
    company: ICompany;
    person: IPerson;
};

export interface ICompanyService {
    createCompany: (data: CompanyCreationData) => Promise<TResult<CompanyCreationResult>>;
    saveCompany: (id: number, data: ICompany) => Promise<TResult<ICompany>>;
    findCompanyById: (id: number) => Promise<ICompany | undefined>;
    removeCompanyById: (id: number) => Promise<TResult<true>>;
}

// Default implementation

export const createCompanyService = (apiService: ISgApiService): ICompanyService => {

    const Actions = {
        GetList: "listaa_yritykset",
        GetByName: "hae_yritys_nimella",
        Create: "lisaa_yritys",
        Update: "tallenna_yritys",
        Remove: "poista_yritys",
    };

    const findCompanyById = async (_: number): Promise<ICompany | undefined> => {
        throw new Error("Not implemented!");
    };

    const createCompany = async (data: CompanyCreationData): Promise<TResult<CompanyCreationResult>> => {
        const payload: { yritys: Omit<ICompanyDTO, "id"> } = {
            yritys: {
                nimi: data.name,
                osoite: data.address,
                postinumero: data.postNumber,
                postitoimipaikka: data.city,
                y_tunnus: data.vatNumber,
            },
        };

        const handleCreateFailure = (errorString: string): TResult<any> => {
            switch (errorString?.toLowerCase()) {
                case "yrityksen nimi on jo käytössä": {
                    return makeFailure(new Error(CompanyCreateError.CompanyNameInUse));
                }
                default: {
                    return makeFailure(new Error(errorString));
                }
            }
        };

        try {
            const result = await apiService.sendAction<IApiResponse & { yritys: ICompanyDTO, henkilo: IPersonDTO }>(TulsuEndpoint, Actions.Create, payload);

            if (result.isSuccess) {
                return makeSuccess({
                    company: CompanyMapper.apiToDomain(result.value.yritys),
                    person: PersonMapper.apiToDomain(result.value.henkilo),
                });
            } else {
                return handleCreateFailure(result.error.message);
            }
        } catch (apiError) {
            return makeFailure(apiError);
        }
    };

    const saveCompany = async (id: number, data: ICompany): Promise<TResult<ICompany>> => {
        const payload = {
            yritys: {
                ...(CompanyMapper.domainToApi(data)),
                id,
            }
        };

        try {
            const result = await apiService.sendAction<IApiResponse & { yritys: ICompanyDTO }>(TulsuEndpoint, Actions.Update, payload);

            if (result.isSuccess) {
                return makeSuccess(CompanyMapper.apiToDomain(result.value.yritys));
            } else {
                return makeFailure(result.error);
            }
        } catch (apiError) {
            return makeFailure(apiError);
        }
    };

    const removeCompanyById = async (id: number): Promise<TResult<true>> => {
        const payload = { yritys: { id } };
        try {
            const result = await apiService.sendAction(TulsuEndpoint, Actions.Remove, payload);
            if (result.isSuccess) {
                return makeSuccess(true);
            } else {
                return makeFailure(result.error);
            }
        } catch (apiError) {
            return makeFailure(new Error(apiError.message));
        }
    };

    return {
        createCompany,
        saveCompany,
        findCompanyById,
        removeCompanyById,
    };
};
