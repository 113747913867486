const Prefix = "tulsu:company";

export const CompanyCacheKeys = {
    Prefix,
    CompanyInstance: (id?: number) => {
        if (!id) {
            throw new Error("notready");
        }
        return [Prefix, id.toString()];
    },
};
