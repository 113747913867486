import React, { Fragment } from "react";
import { useAuthContext } from "../../AuthenticationProvider";

/**
 * A simple guard component. Renders it's children only when a valid login
 * exists in the authentication context.
 */
export const LoggedInGuard: React.FC = ({ children }) => {
    const context = useAuthContext();

    if (!context.user) {
        return null;
    }

    return <Fragment>{ children }</Fragment>;
};
