import React, { PropsWithChildren } from "react";
import { AuthenticationProvider } from "./@authentication/AuthenticationProvider";
import { useAuthentication } from "./@authentication/hooks/useAuthentication";

import "react-toastify/dist/ReactToastify.css";
import { CompanyProvider } from "./@company/context/CompanyContext";
import { CookieConsentProvider } from "./@common/components/cookieConsent/CookieConsentProvider";

/**
 * In this component you can append all the required provider
 * components for the application. The entire app will be wrapped
 * in this component.
 *
 * Injecting dependencies to the contexts will be done within this
 * component.
 */

export const ContextInjection: React.FC<PropsWithChildren<{}>> = ({ children }) => {

    return (
        <AuthenticationProvider useAuthentication={useAuthentication}>
            <CookieConsentProvider>
                <CompanyProvider>
                    { children }
                </CompanyProvider>
            </CookieConsentProvider>
        </AuthenticationProvider>
    );
};
