import { createEndpointMaker } from './services/EndpointService';

const ENDPOINT_HOTWORK = process.env.REACT_APP_ENDPOINT_HOTWORK;
const ENDPOINT_COMMON = process.env.REACT_APP_ENDPOINT_COMMON;
const ENDPOINT_UPLOAD = process.env.REACT_APP_ENDPOINT_UPLOAD;
const ENDPOINT_PAYMENT = process.env.REACT_APP_ENDPOINT_PAYMENT;

if (!ENDPOINT_HOTWORK) {
    throw new Error("REACT_APP_ENDPOINT_HOTWORK missing");
}
if (!ENDPOINT_COMMON) {
    throw new Error("REACT_APP_ENDPOINT_COMMON missing");
}
if (!ENDPOINT_UPLOAD) {
    throw new Error("REACT_APP_ENDPOINT_UPLOAD missing");
}
if (!ENDPOINT_PAYMENT) {
    throw new Error("REACT_APP_ENDPOINT_PAYMENT missing")
}

if (!process.env.REACT_APP_API_BASE_URL) {
    throw new Error("REACT_APP_API_BASE_URL missing!");
}

if (!process.env.REACT_APP_FACADE_URL) {
    throw new Error("REACT_APP_FACADE_URL missing!");
}

const creator = createEndpointMaker(process.env.REACT_APP_API_BASE_URL as string);
const facadeCreator = createEndpointMaker(process.env.REACT_APP_FACADE_URL as string);

export const TulsuEndpoint = creator([ENDPOINT_HOTWORK]);

export const YleisetEndpoint = creator([ENDPOINT_COMMON]);

export const Upload6Endpoint = creator([ENDPOINT_UPLOAD]);

export const FacadeEndpoint = {
    Permit: {
        Create: facadeCreator(["api/permit"]),
    },
};

export const PaymentEndpoint = createEndpointMaker(ENDPOINT_PAYMENT)([]);
