import { IUserBasicInfo } from '../../@authentication/models/IUserBasicInfo';
import { produce } from 'immer';
import { UserCacheKeys } from '../../@authentication/cacheKeys';
import { useSWRCache } from '../../@common/swr/useSWRCache';
import { useAuthContext } from '../../@authentication/AuthenticationProvider';
import { getSgApiService } from '../../@api/services/ApiService';
import { createCompanyService } from '../services/CompanyService';
import { useLoading } from '../../sg-hooks/useLoading';
import { ICompany } from '../models/ICompany';
import { useState } from 'react';

type ICreateCompanyFormData = ICompany;

interface ICreateCompanyData {
    create: (company: ICreateCompanyFormData) => void;
    creating: boolean;
    createdCompany?: ICompany;
    createError?: string;
}

export type CreateCompanyHook = (initialData?: Partial<ICreateCompanyData>) => ICreateCompanyData;

// Default implementation

export const useCreateCompany: CreateCompanyHook = () => {
    const {
        loadErrored: createErrored,
        loadSuccess: createSuccess,
        loading: creating,
        startLoading: startCreating,
        loadError: createError,
    } = useLoading();

    const { token } = useAuthContext();
    const [company, setCompany] = useState<ICompany>();

    const { cache, mutate } = useSWRCache();

    const create = async (company: ICreateCompanyFormData) => {
        if (!creating) {
            try {
                startCreating();
                const api = getSgApiService(token);
                const companyService = createCompanyService(api);

                const result = await companyService.createCompany(company);

                if (result.isSuccess) {
                    try {
                        const userData: IUserBasicInfo = cache.get(UserCacheKeys.basicInfo(token));

                        if (userData) {
                            const newUserData = produce(userData, (draft) => {
                                draft.companies.push(result.value.company);
                                draft.persons.push(result.value.person);
                            });

                            await mutate(UserCacheKeys.basicInfo(token), newUserData, true);
                        }

                        setCompany(result.value.company);
                        createSuccess();
                    } catch (error) {
                        createErrored(error.message);
                    }
                } else {
                    createErrored(result.error.message);
                }
            } catch (error) {
                createErrored(error.message);
            }
        }
    };

    return {
        creating,
        createError,
        createdCompany: company,
        create,
    };
};

