
// Unique symbol for ensuring endpoint values. This prevents
// invalid endpoints being manually created in other places in the
// code.
// THIS SHOULD NOT BE EXPORTED
const endpointSymbol = Symbol("endpoint");

export interface IEndpoint {
    [endpointSymbol]: true;
    readonly path: string;
}

export const createEndpointMaker = (baseUrl: string) => {

    if (!baseUrl) {
        throw new Error(`Cannot create endpoint maker without a valid base url. Given url was '${baseUrl}'.`);
    }

    // Remove trailing slash
    const baseUrlTrimmed = baseUrl[baseUrl.length - 1] === "/"
                            ? baseUrl.substr(0, baseUrl.length - 1)
                            : baseUrl;
    
    // Paths will be later joined together with slashes, so we remove them first with this function
    const removeSlashes = (str: string): string => {
        const startWithSlash = str[0] === "/";
        const endsWithSlash = str.length > 0 && str[str.length - 1] === "/";

        return str.slice(
            startWithSlash ? 1 : 0,
            endsWithSlash ? str.length - 1 : str.length,
        );
    };

    return (path: string[]): IEndpoint => ({
        [endpointSymbol]: true,
        path: `${baseUrlTrimmed}/${path.map(removeSlashes).join("/")}`,
    });
};
