import React from "react";
import { Header } from "semantic-ui-react";
import { PageContainer } from "./PageContainer";
import {Trans, useTranslation} from "react-i18next";
import {BackButton} from "../navigation/BackButton";
import { Link } from "react-router-dom";

export const CookieInfoView: React.FC = () => {
    const texts = useTextProps();

    return (
        <PageContainer>

            <Link to={"/"}>
                <BackButton content={texts.backBtnText} />
            </Link>


            <Header as="h2" content={texts.infoTitle} />

            <p>{texts.whatIsCookie}</p>

            <p>{texts.whyWeCollect}</p>

            <p>
                <Trans i18nKey="cookie_consent.page.what_we_collect">
                    Kävijädataa (kävijämäärät, palvelussa liikkuminen, toimintojen käyttö) keräämme ja analysoimme <strong>Google
                    Analytics</strong>in avulla. Lisätietoja Googlen evästeistä löydät täältä: <a
                    href="https://policies.google.com/technologies/cookies" target="_blank" rel={"noreferrer"}>Googlen
                    evästekäytäntö.</a> Evästetiedot auttavat meitä parantamaan palvelumme toimivuutta ja käytettävyyttä.
                </Trans>
            </p>

            <Header as="h2" content={texts.blockCookiesTitle} />

            <p>{texts.blockCookiesGuide}</p>

            <p>{texts.blockCookiesWarning}</p>

        </PageContainer>
    );
};

const useTextProps = () => {
    const { t } = useTranslation();

    return {
        backBtnText: t("generic.navigation.backToFrontpage"),
        infoTitle: t("cookie_consent.page.cookie_info_title"),
        whatIsCookie: t("cookie_consent.page.what_is_cookie"),
        whyWeCollect: t("cookie_consent.page.why_we_collect"),
        blockCookiesTitle: t("cookie_consent.page.block_cookies_title"),
        blockCookiesGuide: t("cookie_consent.page.block_cookies_guide"),
        blockCookiesWarning: t("cookie_consent.page.block_cookies_warning"),
    };
};
