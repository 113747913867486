import { useLoggedInPerson } from './useLoggedInUser';

export const useCurrentPermissions = () => {
    const person = useLoggedInPerson({ undefinedOnError: true });

    if (person) {
        return {
            isMaintainer: !!person.isAdmin,
            isIssuer: !!person.isIssuer,
        };
    } else {
        return {
            isMaintainer: false,
            isIssuer: false,
        }
    }    
};
