import { base64ToJson, jsonToBase64 } from './../../sg-core/utils/json.helpers';
import { gateBooleanToBoolean } from './../../sg-core/utils/GateSupport';
import { IPersonDTO } from './../dtos/IPersonDTO';
import { IMapper } from './IMapper';
import { IPerson, IPersonHotworkCard } from '../../@common/models/Person';

interface IFormData {
    cards?: IPersonHotworkCard[];
}

export const PersonMapper: IMapper<IPersonDTO, IPerson> = {
    apiToDomain: (api) => {

        const formData: IFormData = base64ToJson(api.lomakedata || jsonToBase64({}));

        const email = api.meiliosoite === "null" ? "" : api.meiliosoite; // god fucking damnit

        return ({
            cards: (formData.cards ?? []).map((card): IPersonHotworkCard => ({
                ...card,
                validityEnds: new Date(card.validityEnds),
            })),
            firstName: api.etunimi ?? "",
            id: api.id,
            isAdmin: gateBooleanToBoolean(api.on_yllapitaja ?? ""),
            isIssuer: gateBooleanToBoolean(api.on_myontaja ?? ""),
            surname: api.sukunimi ?? "",
            email,
            phone:api.puhelin,
            companyId: api.yritys!,
            deleted: gateBooleanToBoolean(api.deleted ?? ""),
        });
    },
    domainToApi: (domain) => {

        const lomakedata: IFormData = {
            cards: domain.cards,
        };

        return {
            id: domain.id,
            etunimi: domain.firstName,
            sukunimi: domain.surname,
            meiliosoite: domain.email ? domain.email : "", // Because NAS doesn't understand null x,x
            puhelin:domain.phone,
            on_myontaja: domain.isIssuer ? "Y" : "N",
            on_yllapitaja: domain.isAdmin ? "Y" : "N",
            yritys: domain.companyId,
            deleted: domain.deleted ? "Y" : "N",
            lomakedata: jsonToBase64(lomakedata),
        };
    },
};
