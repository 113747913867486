export const gateBooleanToBoolean = (gateBoolean: string) => gateBoolean === "Y";

export const booleanToGateBoolean = (boolean: boolean) => boolean === true ? "Y" : "N";


export const gateNonstandardDateToDate = (str: string): Date | null => {
    const [date = "", time = ""] = str.split(" ");

    const [day, month, year] = date.split(".");
    const [hour, minute, second] = time.split(":");

    const values = [day, month, year, hour, minute, second].map((v) => parseInt(v, 10));

    const nans = values.filter(Number.isNaN);

    if (nans.length > 0) {
        return null;
    }

    return new Date(
        values[2],
        values[1] - 1,
        values[0],
        values[3],
        values[4],
        values[5],
    );
};
