import React from 'react';
import { TopLevelRouting } from './@common/navigation/TopLevelRouting';
import { BrowserRouter } from 'react-router-dom';
import { TopNav } from './@common/navigation/TopNav';
import { ContextInjection } from './ContextInjection';
import { AuthenticatedRoute } from './@authentication/components/AuthenticatedRoute';
import { ToastContainer } from 'react-toastify';
import { AuthInitializeGuard } from './@authentication/components/AuthInitializeGuard';

import "./i18n";
import ControlledPageScroll from './@common/components/layout/ControlledPageScroll';
import { CookieConsent } from './@common/components/cookieConsent/CookieConsent';

function App() {
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL || "/"}>
            <ContextInjection>
                <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} pauseOnFocusLoss={true} pauseOnHover={true} />
                <TopNav />
                <ControlledPageScroll />

                <CookieConsent />

                {/*
                  Wrap the app content in Auth guard to ensure the authentication is initialized
                  before we try to render any of the views. (This assures proper auth state & prevents
                  unnecessary redirects)
                */}
                <AuthInitializeGuard>
                    <TopLevelRouting authRouteComponent={AuthenticatedRoute} />
                </AuthInitializeGuard>
            </ContextInjection>
        </BrowserRouter>
    );
}

export default App;
