import {getIn, ValidationErrors} from 'final-form';
import {FocusableInput} from "final-form-focus";
import _pick from "lodash/pick";
import { FieldMetaState } from "react-final-form";

const shouldShowError = (meta: FieldMetaState<any>): boolean => {
    // TODO: Temporary solution to prevent the form not displaying error messages in custom fields.
    // Remove once the form is refactored completely
    return (true || meta?.submitFailed || meta?.dirty || meta?.modified) && meta?.error;
};

export const useFinalFormShouldShowError = (meta: any): boolean => {
    return shouldShowError(meta);
};

export const pickSafeFormFieldProps = (props: object) => {
    return _pick(props, [
        "required",
        "error",
    ]);
};

export const CustomFinalFormFocusFunctions = {
    getFormInputs: (name: string) => () => {
        if (typeof document === "undefined") {
            return [];
        }

        const form: HTMLFormElement = document.forms[name as any];

        // cast cheat to get from HTMLFormElement children to FocusableInput
        return form && form.length ? Array.prototype.slice.call(form) : [];
    },

    // Prefixed fields have a dotted error name "prefix.fieldname" instead of a structures
    // object { prefix: { fieldname: "" }} so we have to customize the final-form-focus
    // decorator to account for that.
    findInput: (inputs: FocusableInput[], errors: ValidationErrors = {}) => {
        const getByName = (name: string) => {
            return (errors as any)[name] || getIn(errors, name);
        };

        return inputs.find((input) => input.name && getByName(input.name));
    },
};


