export const CompanyCreateError = {
    CompanyNameInUse: "$COMPANY_NAME_OCCUPIED",
};

export const resolveCompanyCreateErrorMessage = (errorMessage: string, t: (s: string) => string): string => {
    switch (errorMessage) {
        case CompanyCreateError.CompanyNameInUse:
            return t("actions.create.company_name_taken");
        default:
            return errorMessage;
    }
};
