import React, { useEffect, Fragment } from "react";
import { useCompanyContext } from "../../context/CompanyContext";
import { useUserBasicInfo } from "../../../@authentication/hooks/userUserBasicInfo";
import { CompanySelector } from "./CompanySelector";
import { useTranslation } from "react-i18next";
import { DropdownProps, DropdownItemProps, Modal } from "semantic-ui-react";
import { useBool } from "../../../sg-hooks/useBool";
import { CompanyCreateForm } from "../forms/CreateForm";
import { useCreateCompany } from "../../hooks/useCreateCompany";
import { ICompany } from "../../models/ICompany";
import { useNotification } from "../../../@common/notifications/useToast";
import { useHistory } from 'react-router-dom';

const useTextProps = () => {
    const { t } = useTranslation();

    return {
        noSelection: t("company.none_selected"),
        created: t("generic.notifications.added_to_app"),
        createCompany: t("company.create"),
    };
};

export const GlobalCompanySelector: React.FC<DropdownProps & { onSelectCompany?: () => void }> = (dropdownProps) => {
    const texts = useTextProps();
    const toast = useNotification();

    const { onSelectCompany, ...passToDropdown } = dropdownProps;

    const { currentCompanyId, setCurrentCompany } = useCompanyContext();

    const { companies } = useUserBasicInfo();

    const history = useHistory();

    const selectedId = currentCompanyId === -1 ? undefined : currentCompanyId;

    // If a non-existing or non-available company is loaded from localStorage, we want to
    // clear the current company ID to avoid strange bugs.
    useEffect(() => {
        if (currentCompanyId) {
            const currentFound = companies.find((c) => c.id === currentCompanyId);
            if (!currentFound) {
                setCurrentCompany(-1);
            }
        }
    }, [currentCompanyId, companies]);

    // Creator modal controls
    const [creatorModalOpen, openCreator, closeCreator] = useBool(false);

    const addCompanyOptions: DropdownItemProps = {
        key: "add-company",
        onClick: openCreator,
        icon: "plus",
        text: texts.createCompany,
    };

    const handleCompanyCreation = (company: ICompany) => {
        toast.success(`${company.name} ${texts.created}`);

        setCurrentCompany(company.id);
        closeCreator();
    };

    const handleCompanySelect = (id: number) => {
        setCurrentCompany(id);
        onSelectCompany && onSelectCompany();
        history.push('/');
    }

    return (
        <Fragment>
            <CompanySelector
                companies={companies}
                selectedId={selectedId}
                onCompanySelect={handleCompanySelect}
                placeholder={texts.noSelection}
                appendOptions={[addCompanyOptions]}
                {...passToDropdown}
            />
            <Modal
                open={creatorModalOpen}
                closeIcon
                onClose={closeCreator}
                closeOnDimmerClick
                content={<Modal.Content content={<CompanyCreateForm createHook={useCreateCompany} onCreated={handleCompanyCreation} />} />}
            />
        </Fragment>
    );
};
